import { Component, Input, OnInit } from '@angular/core';
import { TextToSpeechResult } from "../../../../../../server/src/text-to-speech-result";

@Component({
  selector: 'app-story-sentence',
  templateUrl: './story-sentence.component.html',
  styleUrls: ['./story-sentence.component.scss']
})
export class StorySentenceComponent implements OnInit {

  constructor() {
    this.textToSpeechResult = {
      text: 'เขาลุกจากเตียงไปอาบน้ำและแต่งตัวเพื่อไปทำงาน',
      audioFileName: '1706566951041.mp3',
      timePoints: [
        { timeSeconds: 0.12546104192733765, markName: '0' },
        { timeSeconds: 0.6195306777954102, markName: '1' },
        { timeSeconds: 1.02784264087677, markName: '2' },
        { timeSeconds: 1.4564791917800903, markName: '3' },
        { timeSeconds: 2.0084071159362793, markName: '4' },
        { timeSeconds: 2.2917356491088867, markName: '5' },
        { timeSeconds: 2.7683334350585938, markName: '6' },
        { timeSeconds: 3.405029058456421, markName: '7' },
        { timeSeconds: 3.6577394008636475, markName: '8' },
        { timeSeconds: 4.134925842285156, markName: '9' },
        { timeSeconds: 4.644809246063232, markName: '10' },
        { timeSeconds: 5.019690036773682, markName: '11' },
        { timeSeconds: 5.3470001220703125, markName: '12' },
        { timeSeconds: 7.611, markName: '13' }
      ],
      words: [
        'เขา', 'ลุก', 'จาก',
        'เตียง', 'ไป', 'อาบ',
        'น้ำ', 'และ', 'แต่ง',
        'ตัว', 'เพื่อ', 'ไป',
        'ทำงาน'
      ]
    }
  }

  ngOnInit() {
  }


  @Input()
  public textToSpeechResult: TextToSpeechResult | undefined;
}
