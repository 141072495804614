export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyCpB-Z6mkaWs7KGuF0NVe_MvDwJW-5QlyQ",
        authDomain: "chatgapnat.firebaseapp.com",
        projectId: "chatgapnat",
        storageBucket: "chatgapnat.appspot.com",
        messagingSenderId: "996149050632",
        appId: "1:996149050632:web:e39becb0a502992b4e00bd",
        measurementId: "G-2KLGLS6HL1"
    }
};
