<div class="container d-flex flex-column">
  <div class="row align-items-center justify-content-center g-0
      min-vh-100">
    <div class="col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0">
      <!-- Card -->
      <div class="card smooth-shadow-md">
        <!-- Card body -->
        <div class="card-body p-6">
          <div class="mb-4">
            <a href="../index.html">
              <img src="../assets/img/brand/logo2.png" class="mb-2 w-100" alt="">
            </a>
          </div>
          <!-- Form -->
          <form>
            <!-- Username -->
            <div class="mb-3">
              <label for="email" class="form-label">Username or email</label>
              <input #userName type="email" id="email" class="form-control" name="email"
                placeholder="Email address here" required="">
            </div>
            <!-- Password -->
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input #userPassword type="password" id="password" class="form-control" name="password"
                placeholder="**************" required="">
            </div>
            <!-- Checkbox -->
            <div class="d-lg-flex justify-content-between align-items-center
                mb-4">
              <div class="form-check custom-checkbox">
                <input type="checkbox" class="form-check-input cursor-pointer" id="rememberme" [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}">
                <label class="form-check-label cursor-pointer" for="rememberme">Remember
                  me</label>
              </div>

            </div>
            <div>
              <!-- Button -->
              <div class="d-grid">
                <button class="btn btn-primary" (click)="authService.SignIn(userName.value, userPassword.value)">Sign
                  in</button>
              </div>

              <div class="d-md-flex justify-content-between mt-4">
                <div class="mb-2 mb-md-0">
                  <a href="sign-up.html" class="fs-5" routerLink="/register-user">Create An
                    Account </a>
                </div>
                <div>
                  <a href="forget-password.html" class="text-inherit
                      fs-5" routerLink="/forgot-password">Forgot your password?</a>
                </div>

              </div>
            </div>


          </form>
        </div>
      </div>
    </div>
  </div>
</div>