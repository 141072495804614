<div class="container d-flex flex-column">
  <div class="row align-items-center justify-content-center g-0
      min-vh-100">
    <div class="col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0">
      <!-- Card -->
      <div class="card smooth-shadow-md">
        <!-- Card body -->
        <div class="card-body p-6">
          <p class="mb-6">Register for a free account.</p>
          <div class="mb-4">
            <a href="../index.html">
              <img src="../assets/img/brand/logo2.png" class="mb-2 w-100" alt="">
            </a>
          </div>
          <!-- Form -->
          <form>
            <!-- Username -->
            <div class="mb-3">
              <label for="username" class="form-label">User Name</label>
              <input type="text" id="username" class="form-control" name="username" placeholder="User Name" required="">
            </div>
            <!-- Email -->
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input #userEmail type="email" id="email" class="form-control" name="email"
                placeholder="Email address here" required="">
            </div>
            <!-- Password -->
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input #userPwd required type="password" id="password" class="form-control" name="password"
                placeholder="**************" required="">
            </div>
            <!-- Password -->
            <div class="mb-3">
              <label for="confirm-password" class="form-label">Confirm
                Password</label>
              <input type="password" id="confirm-password" class="form-control" name="password"
                placeholder="**************" required="">
            </div>
            <!-- Checkbox -->
            <div class="mb-3">
              <div class="form-check custom-checkbox">
                <input type="checkbox" class="form-check-input" id="agreeCheck">
                <label class="form-check-label" for="agreeCheck"><span class="fs-5">I agree to the <a
                      href="terms-condition-page.html">Terms of
                      Service </a>and
                    <a href="privacy-policy-page.html">Privacy Policy.</a></span></label>
              </div>
            </div>
            <div>
              <!-- Button -->
              <div class="d-grid">
                <button type="submit" class="btn btn-primary"
                  (click)="authService.SignUp(userEmail.value, userPwd.value)">
                  Create Free Account
                </button>
              </div>

              <div class="d-md-flex justify-content-between mt-4">
                <div class="mb-2 mb-md-0">
                  <a href="#" class="fs-5" routerLink="/sign-in">Already
                    member? Login </a>
                </div>
                <div>
                  <a href="#" class="text-inherit
                      fs-5" routerLink="/forgot-password">Forgot your password?</a>
                </div>

              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>