import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkout-done',
  templateUrl: './checkout-done.component.html',
  styleUrls: ['./checkout-done.component.scss']
})
export class CheckoutDoneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
