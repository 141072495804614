import { Injectable } from '@angular/core';
import { AuthService } from "./shared/services/auth.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly apiUrlPrefix = '/api';

  constructor(private router: Router) { }

  public async fetch(endpoint: string, init: RequestInit): Promise<Response> {
    const token = localStorage.getItem('token');

    init.headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    };

    const response = await fetch(this.apiUrlPrefix + endpoint, init);

    if (response.status === 401) {
      console.warn("401 received from API call, redirecting to login");
      this.router.navigate(["/sign-in"]);
    }

    return response;
  }
}


