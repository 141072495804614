<div class="main">
  <div class="container h-100 py-5">
    <div class="d-flex flex-column m-2 align-items-center h-100">
      <div class="d-flex flex-row m-2 flex-fill position-relative">
        <language-item-editor #languageItemEditor [languageItem]="languageItem"></language-item-editor>
        <div class="position-absolute w-100 h-100">
          <spinner [running]="false"></spinner>
        </div>
      </div>
      <div class="d-flex flex-row m-2">
        <div class="d-flex flex-column flex-fill mx-3 justify-content-around">
          <h1 *ngIf="getCurrentSentence()">{{getCurrentSentence()}} of {{storyData?.sentences?.length}}</h1>
        </div>
      </div>
      <div class="d-flex flex-row mb-5">
        <div class="d-flex flex-row">
          <button class="btn btn-primary mr-2 cursor-pointer" (click)="prev()">
            <span class="mdi mdi-rewind"></span>
            <span>Prev</span>
          </button>
          <!-- <button class="btn btn-primary mr-2 cursor-pointer" (click)="pause()">
            <span class="mdi mdi-pause mr-2"></span>
            <span>Pause</span>
          </button> -->
          <button class="btn btn-primary mr-2 cursor-pointer" (click)="play()">
            <div class="d-flex flex-column">
              <span class="mdi mdi-play"></span>
              <span>Thai</span>
            </div>
          </button>

          <button class="btn btn-primary mr-2 cursor-pointer" (click)="translate()">
            <div class="d-flex flex-column">
              <span class="mdi mdi-play"></span>
              <span>English</span>
            </div>
          </button>

          <button class="btn btn-primary mr-2 cursor-pointer" (click)="next()">
            <span class="mdi mdi-fast-forward"></span>
            <span>Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>