import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';

// route guard
import { AuthGuard } from './shared/guard/auth.guard';
import { ChatComponent } from "./components/chat/chat.component";
import { AuthActionComponent } from "./components/auth-action/auth-action.component";
import { EmailVerifiedComponent } from "./components/email-verified/email-verified.component";
import { AccountTypesComponent } from "./components/account-types/account-types.component";
import { MainComponent } from "./site/main/main.component";
import { CheckoutComponent } from "./site/checkout/checkout.component";
import { CheckoutDoneComponent } from "./site/checkout-done/checkout-done.component";
import { SelectDefaultRouteGuard } from "./guards/select-default-route.guard";
import { StorySentenceComponent } from "./components/story/story-sentence/story-sentence.component";
import { StoryEditorComponent } from "./components/story/story-editor/story-editor.component";
import { StoryPlayerComponent } from "./components/story/story-player/story-player.component";

const routes: Routes = [
  { path: '', redirectTo: '/select', pathMatch: 'full' },
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard] },
  { path: 'story', component: StorySentenceComponent, canActivate: [AuthGuard] },
  { path: 'story-editor', component: StoryEditorComponent, canActivate: [AuthGuard] },
  { path: 'story-player', component: StoryPlayerComponent, canActivate: [AuthGuard], data: { showHeader: false } },
  { path: 'sign-in', component: SignInComponent, data: { showHeader: false } },
  { path: 'register-user', component: SignUpComponent, data: { showHeader: false } },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { showHeader: false } },
  { path: 'verify-email-address', component: VerifyEmailComponent, data: { showHeader: false } },
  { path: 'email-verified', component: EmailVerifiedComponent, data: { showHeader: false } },
  { path: 'auth-action', component: AuthActionComponent, data: { showHeader: false } },
  { path: 'account-types', component: AccountTypesComponent },
  { path: 'site', component: MainComponent, data: { siteLayout: true }},
  { path: 'checkout', component: CheckoutComponent, data: { siteLayout: true }},
  { path: 'checkout-done', component: CheckoutDoneComponent, data: { siteLayout: true }},
  { path: 'select', canActivate: [SelectDefaultRouteGuard], component: AccountTypesComponent },
  //{ path: '**', redirectTo: '/site'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule {}