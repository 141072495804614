<div class="thai-script mb-1 hover-parent">
  <span>{{languageItem?.th}}</span>
  <span *ngIf="isCreatingThai" class="mdi mdi-voicemail m-2 pulsate"></span>
  <span *ngIf="languageItem?.thAudioInfo" class="mdi mdi-check-circle m-2 text-success"></span>
  <button class="btn btn-sm btn-secondary hover-visible m-2" (click)="speakThai()">
    <span class="mdi mdi-account-voice mr-2"></span>
    <span>Listen</span>
  </button>
  <!-- <button class="btn btn-sm btn-secondary hover-visible m-2" (click)="languageItem ? languageItem.thAudioInfo = undefined : ''">
    <span class="mdi mdi-trash mr-2"></span>
    <span>Clear audio</span>
  </button> -->
</div>
<div class="transliteration mb-2">{{languageItem?.transliteration}}</div>
<div class="english mb-1 hover-parent">
  <span>{{languageItem?.en}}</span>
  <span *ngIf="isCreatingEnglish" class="mdi mdi-voicemail m-2 pulsating"></span>
  <span *ngIf="languageItem?.enAudioInfo" class="mdi mdi-check-circle m-2 text-success"></span>
  <button class="btn btn-sm btn-secondary hover-visible m-2" (click)="speakEnglish()">
    <span class="mdi mdi-account-voice mr-2"></span>
    <span>Listen</span>
  </button>
</div>
