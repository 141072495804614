import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiService } from "src/app/api.service";


//  const stripe = (window as any).Stripe("pk_live_51NikF5CZsjm7xEdyYgCsU2YPn4J9iX7SFkXfF1usPJozDWPUPiwmHHypTxAtgOWTqf1LHP71QlPcWBeJsS70SmbT00uTcJhGL5");

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, AfterViewInit {
  elements: any;
  emailAddress: string = "";
  isLoading = false;


  constructor(private apiService: ApiService) { }

  ngOnInit() {
    // The items the customer wants to buy
  }



  ngAfterViewInit(): void {
    this.initialize();
  }

  // Fetches a payment intent and captures the client secret
  async initialize() {
    const items = [{ id: "basic" }];

    const response = await this.apiService.fetch("/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items }),
    });
    const { clientSecret } = await response.json();

    const appearance = {
      theme: 'stripe',
    };
    //this.elements = stripe.elements({ appearance, clientSecret });

    // const linkAuthenticationElement = this.elements.create("linkAuthentication");
    // linkAuthenticationElement.mount("#link-authentication-element");

    // linkAuthenticationElement.on('change', (event: any) => {
    //   this.emailAddress = event.value.email;
    // });

    const paymentElementOptions = {
      layout: "tabs",
    };

    const paymentElement = this.elements.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element");
  }

  async handleSubmit(event: any) {
    event.preventDefault();
    this.isLoading = true;

    // const { error } = await stripe.confirmPayment({
    //   elements: this.elements,
    //   confirmParams: {
    //     // Make sure to change this to your payment completion page
    //     return_url: "http://localhost:4200/checkout-done",
    //     receipt_email: this.emailAddress,
    //   },
    // });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // if (error.type === "card_error" || error.type === "validation_error") {
    //   this.showMessage(error.message);
    // } else {
    //   this.showMessage("An unexpected error occurred.");
    // }

    this.isLoading = false;
  }

  // Fetches the payment intent status after payment submission
  async checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    // const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

    // switch (paymentIntent.status) {
    //   case "succeeded":
    //     this.showMessage("Payment succeeded!");
    //     break;
    //   case "processing":
    //     this.showMessage("Your payment is processing.");
    //     break;
    //   case "requires_payment_method":
    //     this.showMessage("Your payment was not successful, please try again.");
    //     break;
    //   default:
    //     this.showMessage("Something went wrong.");
    //     break;
    // }
  }

  // ------- UI helpers -------

  showMessage(messageText: string) {
    const messageContainer = document.querySelector("#payment-message");
    if (!messageContainer)
      return;

    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add("hidden");
      messageContainer.textContent = "";
    }, 4000);
  }
}
