<form id="payment-form">
  <div class="mb-4">
    <h2>Checkout</h2>
    <div class="card">

      <div class="d-flex justify-content-between p-3">

        <div class="d-flex flex-column">

          <span>Premium Subscription (billed monthly)</span>
          <a href="#" class="billing">Save 20% with annual billing</a>

        </div>

        <div class="mt-1">
          <sup class="super-price">$19.00</sup>
          <span class="super-month">/ month</span>
        </div>

      </div>

      <hr class="mt-0 line">

      <!-- <div class="p-3">

        <div class="d-flex justify-content-between mb-2">

          <span>Refferal Bonouses</span>
          <span>-$2.00</span>

        </div>

        <div class="d-flex justify-content-between">

          <span>Vat <i class="fa fa-clock-o"></i></span>
          <span>-20%</span>

        </div>


      </div> -->

      <!-- <hr class="mt-0 line"> -->


      <div class="p-3 d-flex justify-content-between">

        <div class="d-flex flex-column">

          <span><strong>Total</strong></span>
        </div>
        <span><strong>$19.00 / month</strong></span>



      </div>

      <!-- 
      <div class="p-3">

        <button class="btn btn-primary btn-block free-button">Try it free for 30 days</button>
        <div class="text-center">
          <a href="#">Have a promo code?</a>
        </div>

      </div> -->
    </div>
  </div>

  <div id="payment-element">
    <div class="spinner" id="spinner"></div>
  </div>
  <button id="submit" (click)="handleSubmit($event)">
    <div [class.hidden]="!isLoading" class="spinner" id="spinner"></div>
    <span [class.hidden]="isLoading" id="button-text">Pay now</span>
  </button>
  <div id="payment-message" class="hidden"></div>
</form>