import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { HighchartsChartModule } from "highcharts-angular";
import { MarkdownModule } from "ngx-markdown";

import { AppComponent } from './app.component';
import { DiagramComponent } from './diagram/diagram.component';
import { ImageSectionComponent } from "./sections/image-section/image-section.component";
import { HtmlSectionComponent } from './html-section/html-section.component';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { AppRoutingModule } from "./app-routing.module";
import { ChatComponent } from './components/chat/chat.component';
import { EmailVerifiedComponent } from './components/email-verified/email-verified.component';
import { AccountTypesComponent } from './components/account-types/account-types.component';
import { RecordButtonComponent } from "./components/record-button/record-button.component";
import { DeepReaderComponent } from "./components/deep-reader/deep-reader.component";
import { StorySentenceComponent } from "./components/story/story-sentence/story-sentence.component";
import { StoryEditorComponent } from "./components/story/story-editor/story-editor.component";
import { LanguageItemEditorComponent } from "./components/story/language-item-editor/language-item-editor.component";
import { StoryPlayerComponent } from "./components/story/story-player/story-player.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";

@NgModule({
  declarations: [
    AppComponent,
    DiagramComponent,
    ImageSectionComponent,
    HtmlSectionComponent,
    DashboardComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    ChatComponent,
    EmailVerifiedComponent,
    AccountTypesComponent,
    RecordButtonComponent,
    DeepReaderComponent,
    StorySentenceComponent,
    StoryEditorComponent,
    LanguageItemEditorComponent,
    StoryPlayerComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MarkdownModule.forRoot(),
    HighchartsChartModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, "ChatGapNat"),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
