<div class="h-100 d-flex flex-column">
    <div class="nav-container" *ngIf="showHeader">
        <nav class="navbar navbar-expand-lg navbar-light">

            <a class="navbar-brand" routerLink="/site"><img class="logo-img"
                    src="assets/img/brand/logo-horizontal.png" /></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="navbar-content collapse navbar-collapse ml-4" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="'/chat'" routerLinkActive="active">Chats<span class="sr-only"></span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="'/story-editor'" routerLinkActive="active">Stories<span class="sr-only"></span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="'/story-player'" [queryParams]="{story: 2, sentence: 0}" routerLinkActive="active">Listen<span class="sr-only"></span></a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" href="#" (click)="scrollHelperService.scrollTo('section1')">Dashboard</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Tools
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" href="#">Word bank</a>
                            <a class="dropdown-item" href="#">Pronounciation trainer</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Settings</a>
                        </div>
                    </li> -->
                </ul>
                <form class="form-inline my-2 my-lg-0">
                    <div class="ml-3" *ngIf="authService.isLoggedIn">
                        <button class="btn btn-outline-secondary" (click)="authService.SignOut()">Log out</button>
                    </div>
                    <div class="ml-3" *ngIf="!authService.isLoggedIn">
                        <button class="btn btn-outline-secondary" [routerLink]="'/sign-in'">Log in</button>
                    </div>
                    <!-- <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-outline-primary my-2 my-sm-0" type="submit">Search</button> -->
                </form>
            </div>

        </nav>
    </div>
    <div class="flex-fill">
        <router-outlet></router-outlet>
    </div>
</div>