import { Component, ElementRef, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { StoryData } from "../story-data";
import { LanguageItemEditorComponent } from "../language-item-editor/language-item-editor.component";
import { StoriesService } from "src/app/services/stories.service";
import { Story } from "src/app/story";
import { ActivatedRoute, Router } from "@angular/router";
import { StoryQueryParams } from "./stories-query-params";
import { ChatCompletionRequestMessage, ChatCompletionRequestMessageRoleEnum } from "openai";
import { OpenaiService } from "src/app/services/openai.service";

@Component({
  selector: 'app-story-editor',
  templateUrl: './story-editor.component.html',
  styleUrls: ['./story-editor.component.scss']
})
export class StoryEditorComponent implements OnInit {
  stories: Story[] = [];
  activeStory: Story | undefined;
  storyData: StoryData | undefined;
  sidebarOpen: boolean = false;
  storyDataJson: string = "";

  analyzeProgress = 0;

  @ViewChild('sentences')
  sentencesAccordion: ElementRef | undefined;

  @ViewChildren(LanguageItemEditorComponent)
  editors: QueryList<LanguageItemEditorComponent> | undefined;


  constructor(
    private storiesService: StoriesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private openaiService: OpenaiService,
    private ngZone: NgZone) { }

  ngOnInit() {
    this.loadStories();

    this.activatedRoute.queryParams.subscribe((queryParams: StoryQueryParams | any) => {
      const storyId = queryParams.story;

      this.setActiveStoryById(storyId);
    });
  }

  async loadStories() {
    this.stories = await this.storiesService.getStories();
    if (this.stories?.length) {
      this.setActiveStoryById();
    }
  }

  async setActiveStoryById(storyId?: string) {
    if (!this.stories.length)
      return;

    if (!storyId) {
      storyId = (<StoryQueryParams>this.activatedRoute.snapshot.queryParams).story || this.stories[0].id;
    }

    if (this.activeStory?.id == storyId)
      return;

    const newActiveStory = this.stories.find(story => story.id == storyId);
    if (newActiveStory) {
      this.setActiveStory(newActiveStory);
    }
  }

  async setActiveStory(story: Story) {
    if (!story)
      return;

    this.activeStory = story;
    this.router.navigate([], {
      queryParams: <StoryQueryParams>{
        story: this.activeStory?.id
      },
      queryParamsHandling: "merge"
    });
    this.storyData = !!story.storyData ? JSON.parse(story.storyData) : undefined;
    story.rawStory = story.rawStory ?? "";
  }

  async deleteStory(story: Story) {
    if (!story?.id)
      return;

    if (!confirm("Are you sure to delete " + story.name))
      return;

    let index = this.stories.indexOf(story);
    this.removeItem(this.stories, story);
    if (story === this.activeStory) {
      this.setActiveStory(this.stories[index] || this.stories[index - 1]);
    }

    this.storiesService.deleteStory(story.id);
  }

  private removeItem(array: any[], item: any) {
    var index = array.indexOf(item);
    while (index !== -1) {
      array.splice(index, 1);
      index = array.indexOf(item);
    }
    return array;
  }

  async analyzeStoryText() {
    const analyzeTimer = setInterval(() => {
      this.analyzeProgress += 10;
      if (this.analyzeProgress >= 100) {
        clearInterval(analyzeTimer);
        this.analyzeProgress = 100;

        setTimeout(async () => {

          this.sentencesAccordion?.nativeElement.click();
        }, 1500);
      }
    }, 250);

    console.log(this.storyData);
  }

  async createNewStory() {
    let newStory: Story = {
      name: "new story",
      description: "",
      storyData: ""
    }

    newStory = await this.storiesService.saveStory(newStory);

    this.stories.unshift(newStory);
    this.setActiveStory(newStory);
    this.sidebarOpen = false;
  }

  async save() {
    if (!this.activeStory)
      return;

    const storyData = JSON.stringify(this.storyData);
    this.activeStory.storyData = storyData;

    this.storiesService.saveStory(this.activeStory);
  }

  public isGeneratingAudio = false;

  async generateAudio() {
    this.isGeneratingAudio = true;

    if (await this.generateNextAudio()) {
      setTimeout(() => {
        if (this.isGeneratingAudio) {
          this.generateAudio();
        }
      }, 0);
    } else {
      this.isGeneratingAudio = false;
    }
  }

  async generateNextAudio(): Promise<boolean> {
    const editorComponents = this.editors?.toArray();
    let nextEditor = editorComponents?.find(editor =>
      this.needsAudio(editor)
    );

    if (!nextEditor)
      return false;

    await nextEditor?.createThai();
    await nextEditor?.createEnglish();

    return true;
  }

  clearAllAudio() {
    if (!confirm("Are you sure you want to clear all audio?"))
      return;

    const editorComponents = this.editors?.toArray();
    editorComponents?.forEach(editorComponent => {
      if (editorComponent.languageItem) {
        editorComponent.languageItem.thAudioInfo = undefined;
        editorComponent.languageItem.enAudioInfo = undefined;
      }
    });

    return true;
  }

  get sentencesCount(): number {
    return this.editors?.toArray().length ?? 0;
  }

  get sentencesWithAudioCount(): number {
    return this.editors?.filter(editor => !this.needsAudio(editor)).length ?? 0;
  }

  private needsAudio(editor: LanguageItemEditorComponent): unknown {
    return (!editor.isCreatingThai && !editor.languageItem?.thAudioInfo) ||
      (!editor.isCreatingEnglish && !editor.languageItem?.enAudioInfo);
  }

  public async onValueChange(event: Event) {
    if (!this.activeStory)
      return;

    const value = (event.target as any).value;
    this.activeStory.rawStory = value;
  }

  async generateJsonForStory() {
    if (!this.activeStory?.rawStory)
      return;

    const messages: ChatCompletionRequestMessage[] = [{
      role: ChatCompletionRequestMessageRoleEnum.System,
      content: "You are a helpful assistant ."
    }, {
      role: ChatCompletionRequestMessageRoleEnum.User,
      content: this.createPrompt(this.activeStory.rawStory)
    }];

    const resultSubscription = this.openaiService.resultChanged$.subscribe((result) => {
      this.ngZone.run(() => {
        this.storyDataJson = result;
      });
    });

    this.storyDataJson = await this.openaiService.completeChat(messages);

    resultSubscription.unsubscribe();
  }

  cancelGeneration() {
    this.openaiService.cancel();
  }

  createPrompt(rawStory: string): string {
    const promt = `Create content for a thai learning app. 
  I will give you some material containing a vocabulary, a story. I need help converting the material to json. As an answer I want the json as correctly fomatted json and nothing else. Just give me the json!
  Give me the complete json for ALL sentences.

  Here are some examples of how I want the transliteration to look like:
  พนักงานของสโลดาวน์มีเสื้อของบริษัทเพื่อให้ดูเหมือนกัน
   Phanákngaan khŏng Slo-daawn mii sûea khŏng bo-rì-sàt phêua hâi duu mǔean kan"
  ฉันเรียนภาษาไทยที่โรงเรียนที่กรุงเทพ
  Chăn riian paa-săa Thai thîi roong riian thîi Grung Thaep.
  ครูที่สอนฉันใช้สมุดที่น่าสนใจมาก
  Kruu thîi sŏn chăn chái sa-mùt thîi nâa sŏn jai mâak.
  แต่มีปัญหาว่าฉันเข้าใจภาษาไทยที่เขียนในสมุดนั้นไม่ได้!
  Dtàe mee pan-hăa wâa chăn khâo jai paa-săa Thai thîi kĭan nai sa-mùt nân mâi dâi!
  
   Also, in the format example below there are no english translations and trasliterations but be sure to add them for every word and sentence.
  
  in the material I give you is a story. Break it up in ALL its sentences and a vocabulary. If I provide you with a vocabulary use word from that otherwise select word from the story to include in the vocabulary.
  Make sure to break up the story in sentences so an object in the sentences collection only includes one sentence. That means that it cannot contain a dot!
  Give me the complete json for ALL sentences and ALL word in the vocabulary in the material.

  Example of Json format to use:
  {
      "name": {
          "th": "วันที่แย่ ๆ ของตะวัน",
          "en": "",
          "transliteration": ""
      },
      "sentences": [
          {
              "th": "",
              "en": "",
              "transliteration": ""
          },
          {
              "th": "",
              "en": "",
              "transliteration": ""
          }
      ],
      "vocabulary": [
          {
              "word": {
                  "th": "",
                  "en": "",
                  "transliteration": ""
              },
              "example": {
                  "th": "",
                  "en": "",
                  "transliteration": ""
              }
          }
      ]
  }
  
  and here is the material:
  ${rawStory}`;

    return promt;
  }
}
