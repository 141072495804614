<div class="container d-flex flex-column">
  <div class="row align-items-center justify-content-center g-0
      min-vh-100">
    <div class="col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0">
      <!-- Card -->
      <div class="card smooth-shadow-md">
        <!-- Card body -->
        <div class="card-body p-6">
          <h3 class="text-center">We need to verify your email</h3>
          <div class="mb-4">
            <a href="../index.html"><img src="../assets/img/brand/logo2.png" class="mb-2 w-100" alt=""></a>
            <ng-container *ngIf="authService.userData as user">
              <p class="text-center">
                We have sent a verification email to <strong>{{ user.email }}</strong>.
              </p>
              <p class="text-center">
                Please check your email and click on the link to <strong>verify your email
                  address</strong>.
              </p>
            </ng-container>
          </div>
          <!-- Form -->
          <form>
            <div class="text-center">
              <span class="btn btn-link" (click)="authService.SendVerificationMail()">Resend verification email</span>
            </div>
            <div class="text-center mt-3">
              <span><a href="#" routerLink="/sign-in">Sign in</a></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>