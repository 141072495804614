import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import AOS from "aos";
import { ScrollHelperService } from "src/app/services/scroll-helper.service";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(
    public router: Router,
    public scrollHelperService: ScrollHelperService) { }

  ngOnInit() {
    AOS.init();
  }

}
