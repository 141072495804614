import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "../shared/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class SelectDefaultRouteGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService) {}

  canActivate(): boolean | UrlTree {
    const isLoggedIn = this.authService.isLoggedIn;// Your logic here to determine if the user is logged in

    if (isLoggedIn) {
      return this.router.parseUrl('/chat');
    } else {
      return this.router.parseUrl('/site');
    }
  }
  
}
