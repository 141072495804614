import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { TextToSpeechResult } from "../../../../../server/src/text-to-speech-result";
import { platformBrowser } from "@angular/platform-browser";
import { TimePoint } from "../../../../../server/src/time-point";

@Component({
  selector: 'deep-reader',
  templateUrl: './deep-reader.component.html',
  styleUrls: ['./deep-reader.component.scss']
})
export class DeepReaderComponent implements OnInit {
  @ViewChild('audioElement') audioElement?: ElementRef;

  _textToSpeechResult?: TextToSpeechResult = undefined;

  replaySentence: boolean = false;
  pauseTimeBeforeReplay: number = 1;
  private _playbackSpeed: number = 1;

  currentWordIndex: number = -1;
  loopStart: number = 0;
  loopEnd: number = 0;
  playing: boolean = false;
  lastCurrentTime = 0;
  startWordIndex = 0;
  stopWordIndex = -1;
  replayTimeout: NodeJS.Timeout | undefined;

  @Input()
  set textToSpeechResult(value: TextToSpeechResult | undefined) {
    this._textToSpeechResult = value;
    this.setup();
    this.cdr.detectChanges();
  }

  @Input()
  spaceBetweenWords = false;

  get textToSpeechResult(): TextToSpeechResult | undefined {
    return this._textToSpeechResult;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone) { }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        const audio = this.audioElement?.nativeElement;
        const currentTime = audio.currentTime;
        if (currentTime != this.lastCurrentTime) {
          this.onTimeUpdate();
          this.lastCurrentTime = currentTime;
        }
      }, 25);

    });
  }

  setup() {
    // Additional setup logic if needed
  }

  lastWordIndex: number = -1;

  onTimeUpdate() {
    if (!this.textToSpeechResult || !this.textToSpeechResult.timePoints || !this._textToSpeechResult?.words)
      return;

    const words = this._textToSpeechResult?.words;
    const timePoints: TimePoint[] = this._textToSpeechResult.timePoints;

    const audio = this.audioElement?.nativeElement;
    let currentTime = audio.currentTime;
    console.log("Current time:" + currentTime);

    if (currentTime > this.loopEnd) {
      currentTime = this.loopEnd;
    }

    for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {
      if (
        (currentTime - 0.020) >= timePoints[wordIndex].timeSeconds &&
        (currentTime + 0.050) <= timePoints[wordIndex + 1].timeSeconds
      ) {
        this.currentWordIndex = wordIndex;
      }
    }

    console.log(words[this.currentWordIndex]);

    if (this.loopEnd !== 0) {
      if (audio.currentTime > this.loopEnd) {
        audio.currentTime = this.loopEnd;
        console.log("Loop stop!!");
        this.audioElement?.nativeElement.pause();
        if (this.replaySentence) {
          this.replayTimeout = setTimeout(() => {
            this.replayTimeout = undefined;
            audio.currentTime = this.loopStart;
            this.audioElement?.nativeElement.play();
          }, 1000 * this.getPauseTimeBeforeReplay());
        }
      }
    }

    //if (this.lastWordIndex != this.currentWordIndex) {
    this.cdr.detectChanges();
    //}

    this.lastWordIndex = this.currentWordIndex;
  }

  private getPauseTimeBeforeReplay() {
    if (this.pauseTimeBeforeReplay < 0) {
      return (this.loopEnd - this.loopStart + 0.5) / this.playbackSpeed;
    }
    return this.pauseTimeBeforeReplay;
  }

  onWordClick(index: number) {
    const audio = this.audioElement?.nativeElement;
    if (!audio) return;

    const startTime = this.textToSpeechResult?.timePoints?.[index]?.timeSeconds || 0;
    audio.currentTime = startTime;
    this.loopStart = 0;
    this.loopEnd = 0;
    audio.play();
  }

  onWordDblClick(index: number, event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const audio = this.audioElement?.nativeElement;
    if (!audio) return;

    const startTime = this.textToSpeechResult?.timePoints?.[index]?.timeSeconds || 0;
    const endTime = this.textToSpeechResult?.timePoints?.[index + 1]?.timeSeconds || audio.duration;
    audio.currentTime = startTime;
    this.loopStart = startTime;
    this.loopEnd = endTime;
    audio.play();
  }

  onMouseUp() {
    // Implement logic for mouse up event
  }

  public getWord(index: number) {
    if (!this.textToSpeechResult?.words)
      return "";

    let isNotLastWord = index < (this.textToSpeechResult?.words.length - 1);
    let word = this.textToSpeechResult?.words[index] ?? "";
    if (this.spaceBetweenWords && isNotLastWord) {
      word += " ";
    }

    return word;
  }

  getAudioUrl(filename?: string) {
    return "api/audio/" + filename;
  }

  public startWord(index: number, event?: MouseEvent) {
    if (!this.textToSpeechResult)
      return;

    event?.preventDefault();
    event?.stopImmediatePropagation();

    this.startWordIndex = index;

    const audio = this.audioElement?.nativeElement;
    if (!audio) return;

    const startTime = this.textToSpeechResult?.timePoints?.[index]?.timeSeconds || 0;
    const endTime = this.textToSpeechResult?.timePoints?.[index + 1]?.timeSeconds || audio.duration;
    this.loopStart = startTime;
    if (this.loopStart > this.loopEnd) {
      this.loopEnd = audio.duration;
      this.stopWordIndex = this.textToSpeechResult?.words.length - 1;
    }

    audio.currentTime = this.loopStart;
    audio.play();
  }

  public stopWord(index: number, event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.stopWordIndex = index;

    const audio = this.audioElement?.nativeElement;
    if (!audio) return;

    //const startTime = this.textToSpeechResult?.timePoints?.[index]?.timeSeconds || 0;
    const endTime = this.textToSpeechResult?.timePoints?.[index + 1]?.timeSeconds || audio.duration;
    this.loopEnd = endTime;

    if (this.loopStart > this.loopEnd) {
      this.loopStart = 0;
      this.startWordIndex = 0;
    }

    audio.currentTime = this.loopStart;

    audio.play();
  }

  get playbackSpeed(): number {
    return this._playbackSpeed;
  }

  set playbackSpeed(speed: number) {
    this._playbackSpeed = speed;
    if (this.audioElement) {
      this.audioElement.nativeElement.playbackRate = speed;
    }
  }

  cancelReplayTimeout() {
    clearTimeout(this.replayTimeout);
  }

  playAudio(): void {
    if (!this.audioElement)
      return;

    this.cancelReplayTimeout();
    if (this.audioElement.nativeElement.currentTime >= (this.loopEnd - 0.001)) {
      this.audioElement.nativeElement.currentTime = this.loopStart;
    }

    this.audioElement.nativeElement.play();
  }

  pauseAudio(): void {
    if (!this.audioElement)
      return;

    this.cancelReplayTimeout();
    this.audioElement.nativeElement.pause();
  }

  stopAudio(): void {
    if (!this.audioElement)
      return;

    this.cancelReplayTimeout();
    this.audioElement.nativeElement.pause();
    this.audioElement.nativeElement.currentTime = this.loopStart; // Reset the audio to the beginning
  }
}
