import { Component } from "@angular/core";
import { AppCheckService } from "./shared/services/app-check.service";
import { AuthService } from "./shared/services/auth.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import * as FullStory from '@fullstory/browser';
import { ScrollHelperService } from "./services/scroll-helper.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showHeader = true;
  siteLayout = false;

  constructor(
    public authService: AuthService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    public scrollHelperService: ScrollHelperService) {
    FullStory.init({ orgId: 'o-1PR01H-na1' });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const root = this.getRootRoute(this.activatedRoute);
      this.showHeader = root.snapshot.data["showHeader"] ?? true;
      this.siteLayout = root.snapshot.data["siteLayout"] ?? false;
      if (this.siteLayout) {
        document.getElementsByTagName("html")[0].classList.add("layout-site");
      } else {
        document.getElementsByTagName("html")[0].classList.remove("layout-site");
      }
    });
  }

  private getRootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
