import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageFinderService {

  constructor() { }

  imageUrls: string[] = [];

  async getImageUrl(query: string): Promise<string> {
    const API_KEY = 'AIzaSyCUEVP6ovhyzhTyQp2mA95eH7yNYd51fPI';
    const SEARCH_ENGINE_ID = '01d2c824998224826';
    const url = `https://www.googleapis.com/customsearch/v1?searchType=image&key=${API_KEY}&cx=${SEARCH_ENGINE_ID}&q=${query}`;
    const response = await fetch(url);
    const results = await response.json();

    try {
      this.imageUrls = results.items.map((item: any) => item.link);
      const first_result = results.items[0];
      const image_url = first_result.link;
      return image_url;
    } catch (error) {
      return "";
    }
  }

  async getImageFile(search: string): Promise<File> {
    const url = await this.getImageUrl(search);

    const response = await fetch(url);
    const blob = await response.blob();
  
    // Create a File object from the Blob
    return new File([blob], search);
  }
}
