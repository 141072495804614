<div class="container d-flex flex-column">
    <div class="row align-items-center justify-content-center g-0
        min-vh-100">
        <div class="col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0">
            <!-- Card -->
            <div class="card smooth-shadow-md">
                <!-- Card body -->
                <div class="card-body p-6">
                    <h3 class="text-center">Email verified!</h3>
                    <div class="mb-4">
                        <a href="../index.html"><img src="../assets/img/brand/logo2.png" class="mb-2 w-100" alt=""></a>
                        <ng-container *ngIf="authService.userData as user">

                            <p class="text-center">
                                Now we are ready to <strong>get started!</strong>.
                            </p>
                        </ng-container>
                    </div>
                    <!-- Form -->
                    <form>
                        <div class="d-grid">
                            <button type="submit" class="btn btn-primary" routerLink="/">Get started!</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>