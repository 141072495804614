import { Injectable } from '@angular/core';
import { ApiService } from "../api.service";
import { Story } from "../story";

@Injectable({
  providedIn: 'root'
})
export class StoriesService {

  constructor(private apiService: ApiService) { }
  async saveStory(story: Story | undefined): Promise<Story> {
    const response = await this.apiService.fetch("/stories", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(story)
    });

    const data = await response.json();
    return data;
  }

  async getStory(id: string): Promise<Story> {
    const response = await this.apiService.fetch("/stories/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    const data: Story = await response.json();
  
    return data;
  }

  async getStories(): Promise<Story[]> {
    const response = await this.apiService.fetch("/stories", {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    const data = await response.json();
    return data;
  }

  async deleteStory(id: string): Promise<Story> {
    const response = await this.apiService.fetch("/stories/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      }
    });

    const data = await response.json();
    return data;
  }
}
