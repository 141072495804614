import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BrowserFileService {
  public downloadTextFile(textFileContent: string, fileName = "text.txt", mimeType = "text/plain", encoding = "ISO-8859-1") {
    var BOM = new Uint8Array([0xEF,0xBB,0xBF]);

    const options: any = { type: mimeType, encoding: encoding };
    const blob = new Blob([BOM, textFileContent], options);

    const aElement = document.createElement("a");
    aElement.download = fileName;
    aElement.href = URL.createObjectURL(blob);
    aElement.dataset['downloadurl'] = [mimeType, aElement.download, aElement.href].join(":");
    aElement.style.display = "none";
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);
    setTimeout(function () { URL.revokeObjectURL(aElement.href); }, 1500);
  }
}