<div class="main d-flex flex-row h-100 position-relative">

  <div class="sidebar box-shadow border-right d-flex flex-column bg-body-tertiary h-100" [class.closed]="!sidebarOpen">
    <div class="box-shadow sidebar-openbutton border-top border-right border-bottom cursor-pointer"
      (click)="sidebarOpen = !sidebarOpen">
      <i *ngIf="!sidebarOpen" class="fas fa-chevron-right" style="margin-top: 13px;"></i>
      <i *ngIf="sidebarOpen" class="fas fa-chevron-left" style="margin-top: 13px;"></i>
    </div>
    <div class="d-flex flex-row border-bottom mt-2 mb-2 p-2 sidebar-header">
      <div>
        <h5 class="">Stories</h5>
      </div>
      <button class="btn btn-sm btn-outline-primary ml-auto ml-4" (click)="createNewStory()">
        <span class="mdi mdi-forum-plus-outline mr-1"></span>New story</button>
    </div>
    <div class="list-group list-group-flush border-bottom scrollarea flex-fill">
      <ng-container *ngFor="let story of stories">
        <a class="list-group-item list-group-item-action py-3 lh-sm hover-parent cursor-pointer"
          [class.active]="story.id == activeStory?.id" aria-current="true" (click)="setActiveStory(story)">
          <div class="d-flex w-100 align-items-center">
            <span class="mdi mdi-forum-outline mr-1"></span><strong class="mb-1">{{story.name}}</strong>
            <!-- <small class="ml-auto">{{ story.createdAt | date:' HH:mm:ss ' : 'UTC' }}</small> -->
          </div>
          <div class="col-10 mb-1 small">{{story.description ? story.description :
            'Some description of the story.'}}</div>
          <div class="hover-visible d-flex flex-row" style="position: absolute; bottom: 10px; right: 5px">
            <button class="btn ml-2" title="Edit story setting">
              <i class="fas fa-cog"></i>
            </button>
            <button class="btn ml-2" title="Delete story" (click)="deleteStory(story)">
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </a>
      </ng-container>
    </div>
  </div>

  <div class="container p-0 h-100 d-flex flex-column selectable-text">
    <div class="header d-flex flex-row align-items-center mt-2">
      <h4 class="ml-3">Story: {{storyData?.name?.th}} ({{storyData?.name?.en}})</h4>
    </div>
    <div id="accordion">
      <div class="card m-2">
        <div class="card-header" id="heading1">
          <h5 class="mb-0">
            <button class="btn btn-link" data-toggle="collapse" data-target="#collapse1" aria-expanded="true"
              aria-controls="collapse1">
              Story - Raw text
            </button>
          </h5>
        </div>

        <div id="collapse1" class="collapse show" aria-labelledby="heading1" data-parent="#accordion">
          <div class="card-body">
            <textarea [value]="this.activeStory?.rawStory" rows="20" type="text" class="form-control"
              (input)="onValueChange($event)"
              placeholder="Enter the raw text of the story. The story, the glossary and any other input...">
              </textarea>
            <div class="d-flex flex-row w-100 mx-3 mt-3">
              <button class="btn btn-primary mr-2" [disabled]="!this.activeStory?.rawStory"
                (click)="generateJsonForStory()">Analyze</button>
              <button class="btn btn-primary" (click)="cancelGeneration()">Cancel</button>
              <div class="d-flex flex-column flex-fill mx-3 justify-content-around" *ngIf="analyzeProgress">
                <div name="progress" class="progress">
                  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    [style.width.%]="analyzeProgress">{{analyzeProgress}}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="accordion">
        <div class="card m-2">
          <div class="card-header" id="heading6">
            <h5 class="mb-0">
              <button class="btn btn-link" data-toggle="collapse" data-target="#collapse6" aria-expanded="true"
                aria-controls="collapse1">
                Story - Analyzed ({{this.storyDataJson.length}} characters generated)
              </button>
            </h5>
          </div>

          <div id="collapse6" class="collapse show" aria-labelledby="heading6" data-parent="#accordion">
            <div class="card-body">
              <textarea [value]="this.storyDataJson" rows="20" type="text" class="form-control"
                (input)="onValueChange($event)">
                  </textarea>
            </div>
          </div>
        </div>

        <div class="d-flex flex-row m-2">
          <button class="btn btn-primary mr-2"
            [disabled]="!storyData || isGeneratingAudio || (sentencesWithAudioCount == sentencesCount)"
            (click)="generateAudio()">
            <span class="mdi mdi-account-voice mr-2"></span>
            <span>Generate audio</span>
          </button>
          <button class="btn btn-primary mr-2"
            [disabled]="!storyData || isGeneratingAudio || (sentencesWithAudioCount == 0)" (click)="clearAllAudio()">
            <span class="mdi mdi-clear mr-2"></span>
            <span>Clear all audio</span>
          </button>
          <div class="d-flex flex-column flex-fill mx-3 justify-content-around">
            <div name="progress" class="progress position-relative">
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                [style.width.%]="sentencesWithAudioCount/sentencesCount*100">
                <span *ngIf="sentencesWithAudioCount">{{sentencesWithAudioCount}} of {{sentencesCount}}</span>
              </div>
            </div>
          </div>
          <div class="ml-auto">
            <button class="btn btn-primary mr-2" [disabled]="!isGeneratingAudio" (click)="isGeneratingAudio = false">
              <span class="mdi mdi-pause mr-2"></span>
              <span>Pause generation</span>
            </button>
            <button class="btn btn-primary" [disabled]="false" (click)="save()">
              <span>Save</span>
            </button>
          </div>
        </div>

        <div class="card m-2">
          <div class="card-header" id="heading2">
            <h5 class="mb-0 d-flex justify-content-between align-items-center">
              <button #sentences class="btn btn-link" data-toggle="collapse" data-target="#collapse2"
                aria-expanded="true" aria-controls="collapse2">
                <span>Sentences ({{storyData?.sentences?.length ?? 0}} items)</span>
              </button>
            </h5>
          </div>

          <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordion">
            <div class="card-body">
              <div *ngFor="let sentence of storyData?.sentences">
                <language-item-editor [languageItem]="sentence"></language-item-editor>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card m-2">
        <div class="card-header" id="heading3">
          <h5 class="mb-0 d-flex justify-content-between align-items-center">
            <button #sentences class="btn btn-link" data-toggle="collapse" data-target="#collapse3" aria-expanded="true"
              aria-controls="collapse3">
              <span>Vocabulary ({{storyData?.vocabulary?.length ?? 0}} items)</span>
            </button>
          </h5>
        </div>

        <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#accordion">
          <div class="card-body d-flex flex-column">
            <div class="vocabulary-item-container d-flex flex-row">
              <h5 class="flex-fill">Word</h5>
              <h5 class="flex-fill">Example</h5>
            </div>
            <hr>
            <div *ngFor="let vocabularyItem of storyData?.vocabulary">
              <div class="vocabulary-item-container d-flex flex-row justify-content-around">
                <div class="vocabulary-word-container b-1 w-50">
                  <language-item-editor [languageItem]="vocabularyItem.word"></language-item-editor>
                </div>
                <div class="vocabulary-example-container b-1 w-50">
                  <language-item-editor [languageItem]="vocabularyItem.example"></language-item-editor>
                </div>
              </div>
              <hr>
            </div>
          </div>
        </div>
      </div>

      <div class="card m-2">
        <div class="card-header" id="heading4">
          <h5 class="mb-0 d-flex justify-content-between align-items-center">
            <button #sentences class="btn btn-link" data-toggle="collapse" data-target="#collapse4" aria-expanded="true"
              aria-controls="collapse4">
              <span>Questions ({{storyData?.questions?.length ?? 0}} items)</span>
            </button>
          </h5>
        </div>

        <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordion">
          <div class="card-body d-flex flex-column">
            <div class="vocabulary-item-container d-flex flex-row">
              <h5 class="flex-fill">Question</h5>
              <h5 class="flex-fill">Answer</h5>
            </div>
            <hr>
            <div *ngFor="let questionItem of storyData?.questions">
              <div class="vocabulary-item-container d-flex flex-row justify-content-around">
                <div class="vocabulary-word-container b-1 w-50">
                  <language-item-editor [languageItem]="questionItem.question"></language-item-editor>
                </div>
                <div class="vocabulary-example-container b-1 w-50">
                  <language-item-editor [languageItem]="questionItem.answer"></language-item-editor>
                </div>
              </div>
              <hr>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>