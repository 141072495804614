import { Component, OnInit } from '@angular/core';
import { FirebaseApp, provideFirebaseApp } from "@angular/fire/app";
import { ActivatedRoute, Router } from "@angular/router";
import firebase, { getApp, initializeApp } from 'firebase/app';
import { applyActionCode, getAuth } from "firebase/auth";
import { environment } from "src/environments/environment";
import { FirebaseEmailActionUrlQueryParams } from "./firebase-email-action-url-query-params";
import { FirebaseUrlModes } from "./firebase-url-modes.enum";

@Component({
  selector: 'app-auth-action',
  templateUrl: './auth-action.component.html',
  styleUrls: ['./auth-action.component.scss']
})
export class AuthActionComponent implements OnInit {

  constructor(
    activatedRoute: ActivatedRoute,
    private router: Router) { 
    activatedRoute.queryParams.subscribe(queryParams => {
      console.log("QueryParams:", queryParams)
      const mode = (<FirebaseEmailActionUrlQueryParams>queryParams).mode;
      const actionCode = (<FirebaseEmailActionUrlQueryParams>queryParams).oobCode;
      let app: FirebaseApp;
      try {
        app = getApp(); 
      } catch {
        app = initializeApp(environment.firebaseConfig);
      }

      this.verifyEmail(app, actionCode, );
    });
  }

  private verifyEmail(app: firebase.FirebaseApp, actionCode: string) {
    const auth = getAuth(app);
    applyActionCode(auth, actionCode).then((resp) => {
      this.router.navigate(["email-verified"]);
    }).catch((error) => {
      alert("Code is invalid:" + error);
    });
  }

  ngOnInit() {
  }
}
