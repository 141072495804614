<div>
  <audio class="w-100" #audioElement controls [src]="getAudioUrl(textToSpeechResult?.audioFileName)"></audio>
</div>
<div class="words-container mb-5" (mouseup)="onMouseUp()">
  <div *ngFor="let wordInfo of textToSpeechResult?.words; let i = index"
    class="word cursor-pointer hover-parent position-relative" [ngClass]="{'highlight': i === currentWordIndex}"
    (click)="onWordClick(i)" (dblclick)="onWordDblClick(i, $event)" [id]="'word-' + i">
    <span>{{getWord(i)}}</span>
    <div *ngIf="i === currentWordIndex" [style.width]="'100%'" class="underline"></div>
    <div class="word-command-container d-flex flex-row position-absolute w-100 pt-1">
      <div class="word-button-container" [class.selected]="startWordIndex == i">
        <i class="fa fa-play word-command-button hover-visible" [class.selected-word-button]="startWordIndex == i"
          aria-hidden="true" (click)="startWord(i, $event)"></i>
      </div>
      <div class="word-button-container" [class.selected]="stopWordIndex == i">
        <i class="fa fa-play fa-flip-horizontal word-command-button hover-visible"
          [class.selected-word-button]="stopWordIndex == i" aria-hidden="true" (click)="stopWord(i, $event)"></i>
      </div>
    </div>
  </div>
</div>
<div class="reader-command-container">
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="spaceBetweenWordsCheckbox" [(ngModel)]="spaceBetweenWords">
    <label class="form-check-label" for="spaceBetweenWordsCheckbox">Space Between Words</label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="replaySentenceCheckbox" [(ngModel)]="replaySentence">
    <label class="form-check-label" for="replaySentenceCheckbox">Replay Sentence</label>
  </div>
  <div class="mb-3">
    <label for="pauseTimeInput" class="form-label">Pause Time Before Replay (seconds)</label>
    <input type="number" class="form-control" id="pauseTimeInput" [(ngModel)]="pauseTimeBeforeReplay">
  </div>
  <button class="btn btn-primary" (click)="playAudio()">Play</button>
  <button class="btn btn-secondary" (click)="stopAudio()">Stop</button>
  <button class="btn btn-secondary" (click)="pauseAudio()">Pause</button>
  <div class="mb-3">
    <label for="playbackSpeedSelect" class="form-label">Playback Speed</label>

    <button class="btn btn-primary" (click)="playbackSpeed = 0.5" [class.active]="playbackSpeed == 0.5">Very slow</button>
    <button class="btn btn-primary" (click)="playbackSpeed = 0.75"
      [class.active]="playbackSpeed == 0.75">Slow</button>
    <button class="btn btn-primary" (click)="playbackSpeed = 1.0" [class.active]="playbackSpeed == 1.0">Medium</button>
    <button class="btn btn-primary" (click)="playbackSpeed = 1.25" [class.active]="playbackSpeed == 1.25">Fast</button>
    <button class="btn btn-primary" (click)="playbackSpeed = 1.5" [class.active]="playbackSpeed == 1.5">Very fast</button>
  </div>
</div>