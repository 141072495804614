<div class="modal fade fs-unmask" id="settings-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel2"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-slideout" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title align-right" id="exampleModalLabel">Settings</h5>
      </div>
      <div class="modal-body d-flex flex-column">
        <div class="form-check mb-3">
          <input mdbCheckbox class="form-check-input" type="checkbox" id="flexCheckChecked"
            [(ngModel)]="showFullDefault" />
          <label class="form-check-label" for="flexCheckChecked">
            Show translation/transliteration
          </label>
        </div>
        <div class="form-check mb-3">
          <input mdbCheckbox class="form-check-input" type="checkbox" id="flexCheckChecked"
            [(ngModel)]="autoSubmitRecording" />
          <label class="form-check-label" for="flexCheckChecked">
            Submit recording
          </label>
        </div>
        <div class="form-check mb-3">
          <input mdbCheckbox class="form-check-input" type="checkbox" id="flexCheckChecked"
            [(ngModel)]="showHiddenMessages" />
          <label class="form-check-label" for="flexCheckChecked">
            Show hidden messages
          </label>
        </div>
        <div class="form-check mb-3">
          <input mdbCheckbox class="form-check-input" type="checkbox" id="flexCheckChecked"
            [(ngModel)]="speakStallMessages" />
          <label class="form-check-label" for="flexCheckChecked">
            Speak "stall" messages
          </label>
        </div>
        <div class="form-check mb-3">
          <input mdbCheckbox class="form-check-input" type="checkbox" id="flexCheckChecked"
            [(ngModel)]="scrollToBottomWhenSelectingConversation" />
          <label class="form-check-label" for="flexCheckChecked">
            Scroll to bottom when selecting conversation
          </label>
        </div>
        <div class="d-flex flex-row">
          <div class="ml-3">
            <button [disabled]="!selectedText" class="btn btn-primary" (click)="correctText(selectedText)"
              [title]="'Correct: ' + selectedText">
              Correct selection</button>
          </div>
          <div class="ml-3">
            <button [disabled]="!selectedText" class="btn btn-primary" (click)="playSelectedText()"
              [title]="'Speak: ' + selectedText">
              Speak selection</button>
          </div>
          <div class="ml-3">
            <button class="btn btn-primary" (click)="test()">
              Test</button>
          </div>
          <div class="ml-3">
            <button class="btn btn-outline-secondary" (click)="clearMessages()">Clear messages</button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="main d-flex flex-row h-100 position-relative">

  <div
    class="sidebar box-shadow border-right d-flex flex-column bg-body-tertiary h-100"
    [class.closed]="!sidebarOpen">
    <div class="box-shadow sidebar-openbutton border-top border-right border-bottom cursor-pointer"
      (click)="sidebarOpen = !sidebarOpen">
      <i *ngIf="!sidebarOpen" class="fas fa-chevron-right" style="margin-top: 13px;"></i>
      <i *ngIf="sidebarOpen" class="fas fa-chevron-left" style="margin-top: 13px;"></i>
    </div>
    <div class="d-flex flex-row border-bottom mt-2 mb-2 p-2 sidebar-header">
      <div>
        <h5 class="">Chats</h5>
      </div>
      <button class="btn btn-sm btn-outline-primary ml-auto ml-4" (click)="newConversation()">
        <span class="mdi mdi-forum-plus-outline mr-1"></span>New chat</button>
    </div>
    <div class="list-group list-group-flush border-bottom scrollarea flex-fill">
      <ng-container *ngFor="let conversation of conversations">
        <a class="list-group-item list-group-item-action py-3 lh-sm hover-parent cursor-pointer"
          [class.active]="conversation.id == activeConversation?.id" aria-current="true"
          (click)="setActiveConversation(conversation)">
          <div class="d-flex w-100 align-items-center">
            <span class="mdi mdi-forum-outline mr-1"></span><strong class="mb-1">{{conversation.name}}</strong>
            <small class="ml-auto">{{ conversation.date | date:' HH:mm:ss ' : 'UTC' }}</small>
          </div>
          <div class="col-10 mb-1 small">{{conversation.description ? conversation.description :
            'Some placeholder content in a paragraph below the heading and date.'}}</div>
          <div class="hover-visible d-flex flex-row" style="position: absolute; bottom: 10px; right: 5px">
            <button class="btn ml-2" (click)="editConversation(conversation)" title="Edit conversation setting">
              <i class="fas fa-cog"></i>
            </button>
            <button class="btn ml-2" (click)="deleteConversation(conversation)" title="Delete conversation">
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </a>
      </ng-container>
    </div>
  </div>

  <div
    class="sidebar-right box-shadow-left border-left d-flex flex-column bg-body-tertiary h-100"
    [class.closed]="!sidebarRightOpen">
    <div class="box-shadow-left sidebar-openbutton border-top border-right border-bottom cursor-pointer"
      (click)="sidebarRightOpen = !sidebarRightOpen">
      <i *ngIf="sidebarRightOpen" class="fas fa-chevron-right" style="margin-top: 13px;"></i>
      <i *ngIf="!sidebarRightOpen" class="fas fa-chevron-left" style="margin-top: 13px;"></i>
    </div>
    <div class="d-flex flex-row border-bottom mt-2 mb-2 p-2 sidebar-header">
      <div>
        <h5 class="">Chat Coach</h5>
      </div>
      <button class="btn btn-sm btn-outline-primary ml-auto ml-4" (click)="newConversation()">
        <span class="mdi mdi-forum-plus-outline mr-1"></span>Level up</button>
    </div>
    <div class="list-group list-group-flush border-bottom scrollarea flex-fill">
      <div class="card-container" *ngIf="selectionInfo">
        <div class="card">
          <h5 class="card-header">Selection info</h5>
          <div class="card-body">
            <h5 class="card-title">{{selectionInfo.text}}</h5>
            <p class="card-text">{{selectionInfo.translation}}</p>
            <div>
              <button class="btn btn-sm btn-secondary" (click)="speakText(selectionInfo.text)">
                <span class="mdi mdi-account-voice mr-2"></span>Speak</button>
              <button class="btn btn-sm btn-secondary ml-4" (click)="speakText(selectionInfo.text, undefined, 0.3)">
                <span class="mdi mdi-account-voice mr-2"></span>Speak slowly</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-container">
        <div class="card">
          <h5 class="card-header">How do i say that?</h5>
          <div class="card-body">
            <h5 class="card-title">Record a description of what you want to say <strong>in english!</strong></h5>
            <p class="card-text">Example: I would like to say that I love my children more than everything.</p>
            <div style="height: 60px;">
              <record-button></record-button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-container">
        <div class="card">
          <h5 class="card-header">Deep reader</h5>
          <div class="card-body">
            <h5 class="card-title">{{lastTextToSpeechResult?.text}}</h5>
            <deep-reader [textToSpeechResult]="lastTextToSpeechResult"></deep-reader>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container p-0 h-100 d-flex flex-column selectable-text">
    <div class="header d-flex flex-row align-items-center mt-2">
      <h4 class="ml-3">{{activeConversation?.name}}</h4>
    </div>

    <div class="card">
      <div class="d-flex flex-column h-100">
        <div class="flex-fill col-12">
          <div class="position-relative h-100">
            <div class="scroll-to-bottom-button-container" [style.opacity]="isScrolledToBottom() ? 0 : 1">
              <button class="btn btn-outline-secondary scroll-to-bottom-button" (click)="scrollToBottom()">
                <div class="" style="transform: scale(1.5)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-arrow-down-circle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                  </svg>
                </div>
              </button>
            </div>
            <div #scrollContainer class="chat-messages p-4 h-100" (scroll)="isScrolledToBottom()">
              <ng-container *ngFor="let message of messages; last as isLastMessage">
                <div>
                  <ng-container *ngIf="(!message.hidden || showHiddenMessages) && !message.type">
                    <div [class.chat-message-right]="isYou(message)" [class.chat-message-left]="!isYou(message)"
                      [class.message-has-diagram]="message.chartConfig" class="pb-4 chat-message">
                      <div class="d-flex flex-column align-items-center px-2 hover-parent">
                        <img [src]="'/assets/img/avatars/'+ (message.user?.avatar) +'.png'"
                          class="rounded-circle mr-1" alt="Chris Wood" width="40" height="40">
                        <div class="text-muted small text-nowrap mt-2">{{message.createdAt | date:'yy-MM-dd HH:mm'}}
                        </div>
                        <button class="btn btn-outline-secondary hover-visible"
                          (click)="deleteMessage(message)">Delete</button>
                      </div>
                      <div class="d-flex flex-column position-relative hover-parent message-content-container">
                        <div class="flex-shrink-1 rounded py-2 px-3 mr-3 w-100 message-content" [title]="message.text">
                          <div class="font-weight-bold mb-1">{{message.user?.name}}</div>
                          <ng-container *ngIf="showHiddenMessages">
                            {{message.text}}
                          </ng-container>
                          <ng-container *ngIf="!showHiddenMessages">
                            <markdown emoji
                              [data]="message.showFull ? message.text : (message.speakText || message.text)">
                            </markdown>
                          </ng-container>

                          <!-- <ng-container *ngFor="let messageSection of message.sections">
                            <markdown *ngIf="messageSection.type == 'text'" emoji [data]="message.showFull ? messageSection.fullText : messageSection.text">
                            </markdown>
                            <diagram *ngIf="messageSection.type == 'chart'" [config]="messageSection.data"
                              class="d-block mb-2"></diagram>
                            <image-section *ngIf="messageSection.type == 'image'" [index]="1"
                              [search]="messageSection.data"></image-section>
                            <html-section *ngIf="messageSection.type == 'html'"
                              [html]="messageSection.data"></html-section>
                            <html-section hidden *ngIf="messageSection.type == 'code'"
                              [html]="getHtmlFromCode(messageSection.data)"></html-section>
                          </ng-container>
                        </ng-container> -->
                          <div class="message-text-hover-container hover-visible"
                            style="position: absolute; top: 5px; right: 5px">
                            <button *ngIf="message.audioFile" class="btn btn-sm btn-secondary hover-visible"
                              (click)="playAudioFile(message.audioFile)">
                              <span class="mdi mdi-account-voice mr-2"></span>Speak</button>
                            <button class="btn btn-sm btn-secondary hover-visible ml-1"
                              *ngIf="!isYou(message) && (message.text && message.speakText!= message.text)"
                              (click)="message.showFull = !message.showFull">
                              <span class="mdi mdi-information-slab-circle-outline mr-1"></span>Toggle info</button>
                            <button class="btn btn-sm btn-secondary hover-visible" *ngIf="isYou(message)"
                              title="Resubmit this message to get a new answer from teacher."
                              (click)="resubmit(message)">
                              <span class="mdi mdi-send-variant-outline mr-1"
                                style="z-index: 2;"></span>Resubmit</button>
                          </div>
                        </div>
                        <div class="input-group d-flex flex-row translation-container" *ngIf="message.translation">
                          <span>{{message.translation}}</span>
                        </div>
                        <ng-container *ngIf="isLastMessage">
                          <div *ngIf="getSuggestions(message)" class="d-flex flex-row justify-content-around mt-2">
                            <button *ngFor="let suggestion  of getSuggestions(message)" type="button"
                              class="btn btn-outline-secondary" [title]="suggestion.full" style="max-width: 300;"
                              (click)="takeSuggestion(suggestion)">{{suggestion.title}}</button>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="(!message.hidden || showHiddenMessages) && message.type == 'button'">
                    <div class="pb-4 chat-message-left">
                      <div class="d-flex flex-row align-items-center px-2">
                        <button type="button" class="btn btn-primary" [title]="message.text" style="max-width: 300;"
                          (click)="download(message)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-filetype-csv" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z">
                            </path>
                          </svg>
                          {{message.text}}
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>

              <div *ngIf="pendingMessage" class="chat-message-left pb-4">
                <div class="chat-message-left pb-4">
                  <div class="d-flex flex-column align-items-center px-2">
                    <img [src]="'/assets/img/avatars/nat-avatar.png'" class="rounded-circle mr-1" alt="Chris Wood"
                      width="40" height="40">
                    <div class="text-muted small text-nowrap mt-2">now</div>
                  </div>
                  <div class="d-flex flex-row align-items-center px-2">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="ml-4">
                      {{this.pendingMessage}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-3 px-4 border-top w-100 d-flex flex-column">
          <div class="input-group d-flex flex-row">
            <div class="input-container flex-fill position-relative">
              <textarea [class.recording-input]="isRecording || waitingForTranscription" [value]="question"
                (keydown.enter)="submit($event)" rows="3" type="text" class="form-control"
                placeholder="Enter message..." (input)="onValueChange($event)">
              </textarea>
              <div class="text-input-spinner" *ngIf="waitingForTranscription">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Parsing audio...</span>
                </div>
              </div>
              <div class="record-button-container">
                <record-button #recordButton (recordingReady)="recordingReady($event)"
                  (isRecording)="isRecording=$event"></record-button>
              </div>
            </div>
            <button *ngIf="!requestAbortController && !currentAudio && !isRecording" [disabled]="!question"
              class="input-button btn btn-primary" (click)="submit($event)">
              <div class="d-flex flex-column">
                <div style="transform: scale(1.5)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send"
                    viewBox="0 0 16 16">
                    <path
                      d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                  </svg>
                </div>
                <span class="mt-2">Submit</span>
              </div>
            </button>
            <button *ngIf="requestAbortController || currentAudio || isRecording" class="input-button btn btn-secondary"
              (click)="abortRequest(); abortAudio(); cancelRecord()">Cancel</button>
          </div>
          <div class="input-group d-flex flex-row translation-container" *ngIf="currentTranslation">
            <span class="mr-1">Translation:</span>
            <span>{{currentTranslation}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="new-chat-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">New chat</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4">
              <div class="card h-100">
                <img src="\assets\img\cards\conversation-raw.webp" class="card-img-top" alt="...">

                <div class="card-body">
                  <h5 class="card-title">Conversation</h5>
                  <p class="card-text">Engage in a fluid conversation about different topics or roleplay.</p>

                </div>
                <div class="card-footer text-center">
                  <a href="#" class="btn btn-primary" data-dismiss="modal"
                    (click)="createChat('Conversation', 'Lets practice by having a simple conversation.')">Start
                    conversation</a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card h-100">
                <img src="\assets\img\cards\grammar-raw.png" class="card-img-top border-bottom" alt="...">

                <div class="card-body">
                  <h5 class="card-title">Grammar</h5>
                  <p class="card-text">Engage in a chat that focuses on different grammatical aspects of Thai.</p>
                </div>

                <div class="card-footer text-center">
                  <a href="#" class="btn btn-primary" data-dismiss="modal"
                    (click)="createChat('Grammar', 'Lets practice some grammar')">Start grammar</a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card h-100">
                <img src="\assets\img\cards\vocabulary-raw.png" class="card-img-top" alt="...">

                <div class="card-body">
                  <h5 class="card-title">Vocabulary</h5>
                  <p class="card-text">Start a chat that focuses on introducing and practicing new vocabulary for
                    specific topics.</p>
                </div>

                <div class="card-footer text-center">
                  <a href="#" class="btn btn-primary" data-dismiss="modal"
                    (click)="createChat('Vocabulary', 'Lets practice some vocabulary')">Start vocablary</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="conversation-settings-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Conversation settings</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="activeConversation">
        <div class="form-group row">
          <label class="col-2 col-form-label" for="text">Name</label>
          <div class="col-10">
            <input id="text" name="text" type="text" class="form-control" [(ngModel)]="activeConversation.name">
          </div>
        </div>
        <div class="form-group row">
          <label for="textarea" class="col-2 col-form-label">Description</label>
          <div class="col-10">
            <textarea id="textarea" name="textarea" cols="40" rows="5" class="form-control"
              [(ngModel)]="activeConversation.description"></textarea>
          </div>
        </div>
        <div class="form-group row">
          <label for="textarea1" class="col-2 col-form-label">Prompt</label>
          <div class="col-10">
            <textarea id="textarea1" name="textarea1" cols="40" rows="5" class="form-control"
              [(ngModel)]="activeConversation.prompt"></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal"
          (click)="saveConversation(activeConversation)">Save</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>