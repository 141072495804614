import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { StoriesService } from "src/app/services/stories.service";
import { StoryData } from "../story-data";
import { Story } from "src/app/story";
import { StoryQueryParams } from "../story-editor/stories-query-params";
import { LanguageItem } from "../language-item";
import { LanguageItemEditorComponent } from "../language-item-editor/language-item-editor.component";

@Component({
  selector: 'app-story-player',
  templateUrl: './story-player.component.html',
  styleUrls: ['./story-player.component.scss']
})
export class StoryPlayerComponent implements OnInit {
  story?: Story;
  storyData?: StoryData;
  languageItem?: LanguageItem;
  sentenceIndex?: number;

  @ViewChild('languageItemEditor')
  languageItemEditor: LanguageItemEditorComponent | undefined;

  constructor(
    private storiesService: StoriesService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async (queryParams: StoryQueryParams | any) => {
      const storyId = queryParams.story;

      if (storyId && storyId != this.story?.id) {
        this.story = await this.storiesService.getStory(storyId);
        this.storyData = !!this.story.storyData ? JSON.parse(this.story.storyData) : undefined;
      }

      const sentence = queryParams.sentence;
      if (sentence !== undefined) {
        this.setActiveSentence(sentence);
      }

    });
  }

  setActiveSentence(index: number) {
    this.sentenceIndex = index;
    if (!this.storyData?.sentences)
      return;

    this.languageItem = this.storyData?.sentences[index];
  }

  play() {
    this.languageItemEditor?.speakThai();
  }

  prev() {
    if (this.sentenceIndex == undefined)
      return;

    this.sentenceIndex--;
    if (this.sentenceIndex < 0) {
      this.sentenceIndex = 0;
    }

    this.setQueryParams();
  }

  next() {
    if (!this.storyData?.sentences)
      return;

    if (this.sentenceIndex == undefined)
      return;

    this.sentenceIndex++;
    if (this.sentenceIndex >= this.storyData?.sentences?.length) {
      this.sentenceIndex = this.storyData?.sentences?.length - 1;
    }
    this.setQueryParams();
  }

  private setQueryParams() {
    this.router.navigate([], {
      queryParams: <StoryQueryParams>{
        story: this.story?.id,
        sentence: this.sentenceIndex
      },
      queryParamsHandling: "merge"
    });
  }

  translate() {
    this.languageItemEditor?.speakEnglish();
  }

  getCurrentSentence() {
    if (this.sentenceIndex == undefined)
      return 0;

    return parseInt(this.sentenceIndex.toString()) + 1;
  }

}
