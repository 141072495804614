import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'record-button',
  templateUrl: './record-button.component.html',
  styleUrls: ['./record-button.component.scss']
})
export class RecordButtonComponent implements OnInit {
  @Output()
  isRecording: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  recordingReady: EventEmitter<Blob> = new EventEmitter<Blob>(true);

  private _isRecordingInternal: boolean = false;
  public set isRecordingInternal(value: boolean) {
    this._isRecordingInternal = value;
    this.isRecording.emit(value);
  }

  public get isRecordingInternal(): boolean {
    return this._isRecordingInternal;
  }

  constructor() { }

  ngOnInit() {
  }

  public recordButtonText = 'Start Recording';
  public mediaRecorder: MediaRecorder | null = null;
  private recordedChunks: any[] = [];

  public async recordAudio() {
    if (!this.mediaRecorder) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        this.recordedChunks = [];
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.start();

        this.mediaRecorder.addEventListener('dataavailable', (e) => {
          this.recordedChunks.push(e.data);
        });

        this.mediaRecorder.addEventListener('stop', async () => {
          this.recordButtonText = 'Start Recording';
          this.isRecordingInternal = false;
          if (!this.cancelRecording) {
            try {
              let recordedBlob = new Blob(this.recordedChunks, {
                type: 'audio/webm'
              });

              this.recordingReady.emit(recordedBlob);
            } finally {
            }
          } else {
            this.cancelRecording = false;
          }
        });

        this.recordButtonText = 'Stop Recording';
        this.isRecordingInternal = true;
      });
    } else {
      this.mediaRecorder.stop();
      this.mediaRecorder.stream.getTracks().forEach((track: any) => {
        track.stop()
      });
      this.mediaRecorder = null;
    }
  }

  private cancelRecording = false;

  public cancelRecord() {
    if (!this.mediaRecorder)
      return;

    this.mediaRecorder.stop();
    this.mediaRecorder = null;
    this.cancelRecording = true;
  }
}
