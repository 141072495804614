import { Injectable } from '@angular/core';
import { WordInfo } from "./wordInfo";
import wordInfos from "./word-infos.json";

const LANG_THAI = "th-TH";
const LANG_ENGLISH = "en-US";

var SpeechSynthesisRecorder: any;

@Injectable({providedIn:"root"})
export class VocabularyService {
    public allVoices = [];

    constructor() { 

    }

    public async createFile(): Promise<Blob> {
        console.log("VOCABULARY Data:", wordInfos);
        let utterances: any[] = [];
        wordInfos.forEach(wordInfo => {
            const wordUtterances = this.createUtterancesFromWordInfo(wordInfo);
            utterances = utterances.concat(wordUtterances);
        });

        return this.createSpeechFile(utterances);
    }

    public createUtterancesFromWordInfo(wordInfo: WordInfo) {
        let utterances = [];
        utterances.push(this.createUtterance(wordInfo.word.en, LANG_ENGLISH));
        utterances.push(this.createUtterance(wordInfo.word.th, LANG_THAI));
        utterances.push(this.createUtterance(wordInfo.word.th, LANG_THAI));
        utterances.push(this.createUtterance(wordInfo.sentence.en, LANG_ENGLISH));
        utterances.push(this.createUtterance(wordInfo.sentence.th, LANG_THAI));
        utterances.push(this.createUtterance(wordInfo.sentence.th, LANG_THAI));
        return utterances;
    }

    public createUtterance(text: string, language: string): SpeechSynthesisUtterance {
        let utterance = new SpeechSynthesisUtterance();
        utterance.voice = this.allVoices[8];
        utterance.lang = language; //"th-TH";
        utterance.text = text; //"เจมส์ถามว่า 'เกิดอะไรขึ้น?";
        if (language === LANG_THAI) {
            utterance.rate = 0.5;
        } else {
            utterance.rate = 0.7;
        }
        
        return utterance;
    }

    public async createSpeechFile(utterances: any[]): Promise<Blob> {
        return new Promise(async resolve => {
            console.log("picking system audio");
            const stream = await navigator.mediaDevices.getDisplayMedia({video:true, audio:true});
            const track = stream.getAudioTracks()[0];
            if(!track)
                throw "System audio not available";
            
            stream.getVideoTracks().forEach(track => track.stop());
            
            const mediaStream = new MediaStream();
            mediaStream.addTrack(track);
            
            const chunks: any = [];
            const mediaRecorder = new MediaRecorder(mediaStream, {bitsPerSecond:128000});
            mediaRecorder.ondataavailable = event => {
                if (event.data.size > 0)
                    chunks.push(event.data);
            }
            mediaRecorder.onstop = () => {
                stream.getTracks().forEach(track => track.stop());
                mediaStream.removeTrack(track);
                resolve(new Blob(chunks));
            }
            mediaRecorder.start();

            utterances[utterances.length - 1].onend = () => mediaRecorder.stop();

            utterances.forEach(utterance =>{
                speechSynthesis.speak(utterance);
            });  
        });
    }

    getAllVoices() {
        let voicesall = speechSynthesis.getVoices();
        let vuris: any[] = [];
        let voices: any[] = [];
        //unfortunately we have to check for duplicates
        voicesall.forEach(function(obj,index){
          let uri = obj.voiceURI;
          if (!vuris.includes(uri)){
              vuris.push(uri);
              voices.push(obj);
           }
        });
        voices.forEach(function(obj,index){obj.id = index;});
        return voices;
      }

    dataJson: string = `[
        {
          "word": {
            "en": "Park a car",
            "th": "จอดรถ",
            "transliteration ": "jot rót"
          },
          "sentence": {
            "en": "James is parking a car.",
            "th": "เจมส์กำลังจอดรถ",
            "transliteration ": "James gamlang jot rót"
          },
          "image_search": "man parking car"
        },
        {
          "word": {
            "en": "Reverse",
            "th": "ถอย",
            "transliteration ": "thoi"
          },
          "sentence": {
            "en": "Sarah is reversing.",
            "th": "ซาร่ากำลังถอย",
            "transliteration ": "Sarah gamlang thoi"
          },
          "image_search": "woman reversing"
        },
        {
          "word": {
            "en": "Reverse a car",
            "th": "ถอยรถ",
            "transliteration ": "thoi rót"
          },
          "sentence": {
            "en": "John is reversing a car.",
            "th": "จอห์นกำลังถอยรถ",
            "transliteration ": "John gamlang thoi rót"
          },
          "image_search": "man reversing car"
        },
        {
          "word": {
            "en": "Turn a car around",
            "th": "กลับรถ",
            "transliteration ": "glap rót"
          },
          "sentence": {
            "en": "Jane is turning the car around.",
            "th": "เจนกำลังกลับรถ",
            "transliteration ": "Jane gamlang glap rót"
          },
          "image_search": "woman turning car around"
        },
        {
          "word": {
            "en": "Compete",
            "th": "แย่ง",
            "transliteration ": "yaeng"
          },
          "sentence": {
            "en": "John and James are competing.",
            "th": "จอห์นและเจมส์กำลังแย่ง",
            "transliteration ": "John læ James gamlang yaeng"
          },
          "image_search": "men competing"
        },
        {
          "word": {
            "en": "Tie",
            "th": "ผูก",
            "transliteration ": "phūk"
          },
          "sentence": {
            "en": "Sarah is tying a knot.",
            "th": "ซาร่ากำลังผูกเงื่อน",
            "transliteration ": "Sarah gamlang phūk nguean"
          },
          "image_search": "woman tying knot"
        },
        {
          "word": {
            "en": "Block",
            "th": "ขวาง",
            "transliteration ": "khwāng"
          },
          "sentence": {
            "en": "Jane is blocking the way.",
            "th": "เจนกำลังขวางทาง",
            "transliteration ": "Jane gamlang khwāng thang"
          },
          "image_search": "woman blocking way"
        }
      ]`;

}



