import { Injectable } from '@angular/core';
import { ApiService } from "../api.service";
import { ChatCompletionRequestMessage, ChatCompletionRequestMessageRoleEnum } from "openai";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OpenaiService {
  _resultChanged$: Subject<string> = new Subject<string>();

  requestAbortController: AbortController | undefined = new AbortController();

  get resultChanged$(): Observable<string> {
    return this._resultChanged$.asObservable();
  }

  constructor(private apiService: ApiService) { }

  public cancel() {
    this.requestAbortController?.abort();
  }

  public async completeChat(messages: ChatCompletionRequestMessage[],): Promise<string> {
    this.requestAbortController = new AbortController();
    const signal = this.requestAbortController?.signal;

    const response = await this.fetchCompletions(messages, signal);

    // Read the response as a stream of data
    const reader = response?.body?.getReader();

    if (!reader)
      throw "No reader";

    const decoder = new TextDecoder("utf-8");
    let resultText = "";
    let retries = 0;
    let chunk = "";
    let oldChunks = "";
    let parsedLines = [];
    while (true) {
      try {
        const { done, value } = await reader.read();
        if (done) {
          this.requestAbortController = undefined;
          break;
        }
        // Massage and parse the chunk of data
        chunk = oldChunks + decoder.decode(value);
        const lines = chunk.split("data:");
        parsedLines = lines
          .map((line) => line.replace(/^data: /, "").trim()) // Remove the "data: " prefix
          .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
          .map((line) => {
            return JSON.parse(line);
          });

        if (!parsedLines?.length) {
          this.requestAbortController = undefined;
          break;
        }

        if (this.requestAbortController?.signal.aborted) {
          return "";
        }

      } catch (error) {
        if (this.requestAbortController?.signal.aborted) {
          return "";
        }
        
        oldChunks += chunk;
        console.error(error);
        retries++;
        if (retries < 3)
          continue;
      }

      for (const parsedLine of parsedLines) {
        const { choices } = parsedLine;
        const { delta } = choices[0];
        const { content } = delta;
        // Update the UI with the new content
        if (content) {
          resultText += content;
          this._resultChanged$.next(resultText);
        }
      }

      chunk = "";
      oldChunks = "";
      parsedLines = [];
    }

    return resultText;
  }

  private async fetchCompletions(messages: ChatCompletionRequestMessage[], signal: AbortSignal): Promise<Response> {
    return await this.apiService.fetch('/completions', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        messages: messages
      }),
      signal,
    });
  }

}
