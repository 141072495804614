<div data-aos-easing="ease-in-out" data-aos-duration="1000" data-aos-delay="0">

  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">

    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 aos-init aos-animate"
          data-aos="fade-up" data-aos-delay="200">
          <h1>Level Up Your Thai,</h1>
          <h1>Chat by Chat</h1>
          <h2>Empowering learners with immersive AI-driven interactions.</h2>
          <div class="d-flex justify-content-center justify-content-lg-start">
            <a
              class="btn-get-started cursor-pointer" (click)="scrollHelperService.scrollTo('pricing')">Get Started</a>
            <a href="https://www.youtube.com/watch?v=qogSBPbjlE4&t=2s" class="glightbox btn-watch-video"><i
                class="bi bi-play-circle"></i><span>Watch Video</span></a>
          </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
          <img src="/assets/img/site/laptop-with-chat.png" class="img-fluid animated" alt="">
        </div>
      </div>
    </div>

  </section><!-- End Hero -->

  <main id="main">

    <!-- ======= About Us Section ======= -->
    <section id="about" class="about">
      <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="section-title">
          <h2>About ChatGapNat</h2>
        </div>
        <div class="row content">
          <div class="col-lg-6">
            <p>
              At ChatGapNat, we believe in the power of genuine conversations as the path to mastering a language. More
              than an app, ChatGapNat is an evolving Thai conversational experience.
            </p>
            <ul>
              <li><i class="ri-check-double-line"></i> Harnessing AI to mimic real-life Thai interactions</li>
              <li><i class="ri-check-double-line"></i> Designed to ignite motivation, fueling faster fluency</li>
              <li><i class="ri-check-double-line"></i> Merging traditional Thai culture with modern tech</li>
            </ul>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
            <p>
              Our innovative platform pushes beyond simple memorization, allowing you to experience Thai language and
              culture through dynamic dialogues. Each chat is an opportunity, each conversation a step closer to
              fluency. With ChatGapNat, watch Thai embed itself in your brain, almost automagically.
            </p>
            <a href="#" class="btn-learn-more">Discover the Magic</a>
          </div>
        </div>
      </div>
    </section><!-- End About ChatGapNat Section -->


    <!-- ======= Why Us Section ======= -->
    <section id="why-us" class="why-us section-bg">
      <div class="container container-fluid aos-init aos-animate" data-aos="fade-up">

        <div class="row">

          <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

            <div class="content pl-0">
              <h3>Why ChatGapNat Is <strong>Your Best Path to Fluency</strong></h3>
              <p>
                In the vast sea of language learning platforms, ChatGapNat stands apart. It's not just about learning;
                it's about genuine conversations and real-world application.
              </p>
            </div>

            <div class="accordion-list pl-0">
              <ul>
                <li>
                  <a data-bs-toggle="collapse" class="collapse" data-bs-target="#accordion-list-1"><span>01</span> What
                    Makes Conversational Learning Unique?</a>
                  <div id="accordion-list-1" class="collapse show" data-bs-parent=".accordion-list">
                    <p>
                      Traditional methods often miss the mark in real-life situations. With ChatGapNat, you engage in
                      actual dialogues, ensuring you're always contextually and culturally apt.
                    </p>
                  </div>
                </li>

                <li>
                  <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" class="collapsed"><span>02</span>
                    How Does ChatGapNat Boost Motivation?</a>
                  <div id="accordion-list-2" class="collapse show" data-bs-parent=".accordion-list">
                    <p>
                      By letting you lead conversations based on your interests, you're not just learning a
                      language—you're expressing your identity. This emotional connection fosters greater dedication and
                      results.
                    </p>
                  </div>
                </li>

                <li>
                  <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" class="collapsed"><span>03</span>
                    Why Choose Us Over Traditional Language Sites?</a>
                  <div id="accordion-list-3" class="collapse show" data-bs-parent=".accordion-list">
                    <p>
                      Say goodbye to constant toggling and disruptions (eg. to Google translate). With ChatGapNat, the tools you need appear exactly when you need them. 
                      From grammar checks to vocabulary suggestions, we ensure that your learning flow remains uninterrupted.
                    </p>
                  </div>
                </li>

              </ul>
            </div>

          </div>

          <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img aos-init aos-animate"
            style="background-image: url(&quot;/assets/img/site/language-learning.png&quot;);" data-aos="zoom-in"
            data-aos-delay="150">&nbsp;</div>
        </div>

      </div>
    </section><!-- End Why Us Section -->


    <!-- ======= Features Section ======= -->
    <section id="services" class="services section-bg">
      <div class="container aos-init aos-animate" data-aos="fade-up">

        <div class="section-title">
          <h2>Features</h2>
          <p>Unveiling a new era in Thai language learning with features meticulously designed to accelerate your path
            to fluency.</p>
        </div>

        <div class="row">
          <div class="col-xl-3 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="zoom-in"
            data-aos-delay="100">
            <div class="icon-box">
              <div class="icon"><i class="bx bx-conversation"></i></div>
              <h4><a href="#">Real Conversations</a></h4>
              <p>Engage in genuine dialogues, mimicking real-life Thai interactions.</p>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0 aos-init aos-animate" data-aos="zoom-in"
            data-aos-delay="200">
            <div class="icon-box">
              <div class="icon"><i class="bx bx-help-circle"></i></div>
              <h4><a href="#">Input Coach</a></h4>
              <p>Express yourself better with on-the-go phrase suggestions and pronunciation help.</p>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0 aos-init aos-animate" data-aos="zoom-in"
            data-aos-delay="300">
            <div class="icon-box">
              <div class="icon"><i class="bx bx-bookmark"></i></div>
              <h4><a href="#">Personalized Word Bank</a></h4>
              <p>Save, review, and reinforce vocabulary with flashcards and repetition.</p>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0 aos-init aos-animate" data-aos="zoom-in"
            data-aos-delay="400">
            <div class="icon-box">
              <div class="icon"><i class="bx bx-microphone"></i></div>
              <h4><a href="#">Instant Pronunciation</a></h4>
              <p>Master Thai tones with immediate feedback and correction.</p>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Features Section -->


    <!-- ======= Cta Section ======= -->
    <section id="cta" class="cta">
      <div class="container aos-init aos-animate" data-aos="zoom-in">

        <div class="row">
          <div class="col-lg-9 text-center text-lg-start">
            <h3>Level Up Your Thai Now!</h3>
            <p> Dive into a revolutionary learning experience with ChatGapNat. Embrace real conversations, make mistakes
              without fear, and let your curiosity lead the way. Your path to Thai fluency awaits.</p>
          </div>
          <div class="col-lg-3 cta-btn-container text-center">
            <a class="cta-btn align-middle cursor-pointer" (click)="scrollHelperService.scrollTo('pricing')">Start
              Chatting</a>
          </div>
        </div>

      </div>
    </section><!-- End Cta Section -->




    <!-- ======= Team Section ======= -->
    <section id="section-team" class="team section-bg">
      <div class="container aos-init aos-animate" data-aos="fade-up">

        <div class="section-title">
          <h2>Meet the Team</h2>
          <p>Get to know the dedicated professionals behind our effective language courses. Our team's expertise and
            passion for teaching enable a unique, immersive learning experience for our students.</p>
        </div>

        <div class="row">

          <div class="col-lg-6 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
            <div class="member d-flex align-items-start">
              <div class="pic"><img src="/assets/img/avatars/nat-avatar.png" class="img-fluid" alt=""></div>
              <div class="member-info">
                <h4>Kruu Nat</h4>
                <span>Experienced Language Teacher and Course Designer</span>
                <p>Kruu Nat, also known as Nattida Chantaramanee, brings over a decade of teaching experience to the
                  table. A graduate of Chulalongkorn University in Bangkok, she's worked at an international language
                  school for five years. Now a freelance teacher based in Koh Lanta, Krabi, Thailand, Nat excels at
                  crafting immersive learning experiences.</p>
                <div class="social">
                  <!-- Add your social media links here -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mt-4 mt-lg-0 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
            <div class="member d-flex align-items-start">
              <div class="pic"><img src="/assets/img/avatars/richard-avatar.png" class="img-fluid" alt=""></div>
              <div class="member-info">
                <h4>Rikki</h4>
                <span>Student and Language Tool Developer</span>
                <p>Rikki, also known as Richard, is an ardent language learner and student of Kruu Nat. Hailing from
                  southern Sweden, he frequently visits Koh Lanta. Richard combines his love for language learning with
                  a knack for developing immersive language tools.</p>
                <div class="social">
                  <!-- Add your social media links here -->
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Team Section -->


    <!-- ======= Pricing Section ======= -->
<section id="pricing" class="pricing">
  <div class="container aos-init" data-aos="fade-up">

    <div class="section-title">
      <h2>Flexible Pricing Plans</h2>
      <p>Choose from our range of account types tailored to fit your learning needs and budget. No hidden fees, cancel anytime.</p>
    </div>

    <div class="row">
      <div class="col-lg-4 aos-init" data-aos="fade-up" data-aos-delay="100">
        <div class="box">
          <h3>Basic</h3>
          <h4><sup>$</sup>5<span> per month</span></h4>
          <ul>
            <li><i class="bx bx-check"></i> Access to basic vocabulary and phrases</li>
            <li><i class="bx bx-check"></i> Limited daily interactions with the AI</li>
            <li><i class="bx bx-check"></i> Basic grammar lessons</li>
          </ul>
          <a href="/checkout" class="buy-btn cursor-pointer">Get Started</a>
        </div>
      </div>

      <div class="col-lg-4 mt-4 mt-lg-0 aos-init" data-aos="fade-up" data-aos-delay="200">
        <div class="box featured">
          <h3>Premium</h3>
          <h4><sup>$</sup>19<span> per month</span></h4>
          <ul>
            <li><i class="bx bx-check"></i> Full AI interactions</li>
            <li><i class="bx bx-check"></i> Access to the 'Input Coach' feature</li>
            <li><i class="bx bx-check"></i> Personalized word bank & flashcards</li>
            <li><i class="bx bx-check"></i> Personalized grammar lessons</li>
            <li><i class="bx bx-check"></i> Priority support</li>
          </ul>
          <a href="/checkout" class="buy-btn cursor-pointer">Get Started</a>
        </div>
      </div>

      <div class="col-lg-4 mt-4 mt-lg-0 aos-init" data-aos="fade-up" data-aos-delay="300">
        <div class="box">
          <h3>Premium plus</h3>
          <h4><sup>$</sup>39<span> per month</span></h4>
          <ul>
            <li><i class="bx bx-check"></i> All features of Premium</li>
            <li><i class="bx bx-check"></i> Exclusive access to cultural lessons and nuances</li>
            <li><i class="bx bx-check"></i> One-on-one monthly consultations with language experts</li>
            <li><i class="bx bx-check"></i> Premium content and resources</li>
            <li><i class="bx bx-check"></i> Advanced tracking and analytics</li>
          </ul>
          <a href="/checkout" class="buy-btn cursor-pointer">Get Started</a>
        </div>
      </div>

    </div>

  </div>
</section><!-- End Pricing Section -->



    <!-- ======= Frequently Asked Questions Section ======= -->
    <section id="faq" class="faq section-bg">
      <div class="container aos-init" data-aos="fade-up">

        <div class="section-title">
          <h2>Frequently Asked Questions</h2>
          <p>Explore the advanced language learning and communication features of ChatGapNat that make it your ideal
            language partner.</p>
        </div>

        <div class="faq-list">
          <ul>
            <li data-aos="fade-up" data-aos-delay="100" class="aos-init">
              <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" class="collapse"
                data-bs-target="#faq-list-1">What is Instant Word Assistance?</a>
              <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
                <p>
                  Instant Word Assistance allows you to highlight any word for an immediate and comprehensive breakdown,
                  including translations, pronunciation guides, and contextual examples.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="200" class="aos-init">
              <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2"
                class="collapsed">How does the Personalized Word Bank work?</a>
              <div id="faq-list-2" class="collapse show" data-bs-parent=".faq-list">
                <p>
                  The Personalized Word Bank allows you to save challenging words. You can then revisit these words
                  through flashcards and other techniques to reinforce your vocabulary understanding.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="300" class="aos-init">
              <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3"
                class="collapsed">What is the 'Input Coach' feature?</a>
              <div id="faq-list-3" class="collapse show" data-bs-parent=".faq-list">
                <p>
                  The Input Coach bridges the gap between your current proficiency and more complex expression. If you
                  struggle to articulate an idea, describe it in your native language, and the Input Coach will provide
                  accurate translations and pronunciation guidance in Thai.
                </p>
              </div>
            </li>

          </ul>
        </div>

      </div>
    </section><!-- End Frequently Asked Questions Section -->


  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer">

    <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <h4>Join Our Newsletter</h4>
            <p>Subscribe for exclusive language learning tips, updates, and special offers from ChatGapNat.</p>
            <form action="" method="post">
              <input type="email" name="email" placeholder="Enter your email">
              <input type="submit" value="Subscribe">
            </form>
          </div>
        </div>
      </div>
    </div>
    

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-contact">
            <h3>ChatGapNat</h3>
            <p>
              Koh Lanta <br>
              
              <strong>Email:</strong>info@chatgapnat.com<br>
            </p>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a
                  href="#">Home</a></li>
              <li><i class="bx bx-chevron-right"></i> <a
                  href="#">About us</a></li>
              <li><i class="bx bx-chevron-right"></i> <a
                  href="#">Terms of service</a></li>
              <li><i class="bx bx-chevron-right"></i> <a
                  href="#">Privacy policy</a></li>
            </ul>
          </div>

        </div>
      </div>
    </div>

    <div class="container footer-bottom clearfix">
      <div class="copyright">
        © Copyright <strong><span>ChatGapNat</span></strong>. All Rights Reserved
      </div>
    </div>
  </footer><!-- End Footer -->


</div>