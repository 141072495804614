import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LanguageItem } from "../language-item";
import { ApiService } from "src/app/api.service";
import { TextToSpeechResult } from "../../../../../../server/src/text-to-speech-result";
import {Howl, Howler} from 'howler';

@Component({
  selector: 'language-item-editor',
  templateUrl: './language-item-editor.component.html',
  styleUrls: ['./language-item-editor.component.scss']
})
export class LanguageItemEditorComponent implements OnInit {

  @Input()
  languageItem: LanguageItem | undefined;

  isCreatingThai = false;
  isCreatingEnglish = false;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
  }

  async speakThai() {
    if (!this.languageItem)
      return;

    await this.createThai();

    this.playAudioInfo(this.languageItem.thAudioInfo, 1.5);
  }

  activeAudioInfo?: TextToSpeechResult;
  currentPlaybackRate = 1;

  public async createThai() {
    if (!this.languageItem)
      return;

    if (!this.languageItem?.thAudioInfo) {
      this.isCreatingThai = true;
      this.languageItem.thAudioInfo = await this.speakText(this.languageItem.th, "th-th", 1);
      this.isCreatingThai = false;
    }
  }

  public async createEnglish() {
    if (!this.languageItem)
      return;

    if (!this.languageItem?.enAudioInfo) {
      this.isCreatingEnglish = true;
      this.languageItem.enAudioInfo = await this.speakText(this.languageItem.en, "en-US", 1);
      this.isCreatingEnglish = false;
    }
  }

  playAudioInfo(audioInfo?: TextToSpeechResult, speed: number = 1) {
    if (!audioInfo )
      return;

      var sound = new Howl({
        src: [<string>this.getAudioUrl(audioInfo.audioFileName)]
      });
      
      sound.play();
  }

  async speakEnglish() {
    if (!this.languageItem)
      return;

    await this.createEnglish();

    this.playAudioInfo(this.languageItem.enAudioInfo);
  }

  getAudioUrl(filename?: string) {
    return !!filename ? "api/audio/" + filename : undefined;
    //return !!filename ? "https://storage.cloud.google.com/chat-gap-nat_audio-files/" + filename : undefined;
  }

  public async speakText(text: string | undefined, languageCode: string = "th-th", speed: number = 1): Promise<TextToSpeechResult | undefined> {
    if (!text)
      return undefined;

    const response = await this.apiService.fetch("/speak", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: text,
        languageCode: languageCode,
        speed: speed
      })
    });

    if (!response.ok) {
      throw new Error('Network response wtransas not ok');
    }

    const result = await response.json();
    console.log("TextToSpeechResult:", result)

    return result;
  }
}
