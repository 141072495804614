<div class="container-fluid p-6">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12">
        <!-- Page header -->
        <div class="border-bottom pb-4 mb-4">             
            <h3 class="mb-0 fw-bold">Pricing</h3>             
        </div>
      </div>
    </div>
    <!-- content -->
    <div class="py-8">
      <div class="row">
        <!-- col -->
        <div class="offset-xl-1 col-xl-10 col-md-12">
          <div class="row mb-10">
            <!-- text -->
            <div class="col-md-12 col-12 mb-6">
              <h1 class="display-4 fw-bold ls-sm">Find a plan that s right for you</h1>
              <div class="d-lg-flex align-items-center mt-4">
                <div>
                  <p class="mb-lg-0">Or simply leverage the expertise of our consultation team.
                  </p>
                </div>
                <div class="ms-0 ms-lg-3">
                  <a href="#" class="btn btn-outline-primary btn-sm">Talk
                      to us</a>
                </div>
              </div>

            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 col-12 mb-3">
              <!-- card -->
              <div class="card">
                <!-- card body -->
                <div class="card-body p-6 border-bottom mb-4">
                  <!-- text -->
                  <h2 class="mb-3">Standard</h2>
                  <p class="mb-0">For early-stage startups that want to spend more time developing and less on manual operations.
                  </p>
                  <!-- price -->
                  <div class="d-flex align-items-end mt-6 mb-3">
                    <h1 class="fw-bold me-1 mb-0">$49 </h1>
                    <p class="mb-0">/mo</p>
                  </div>
                  <!-- button -->
                  <a href="#" class="btn btn-outline-primary">Buy Standard</a>
                </div>
                <!-- card body -->
                <div class="card-body">
                  <p class="mb-0">All core features, including:</p>
                  <ul class="list-unstyled mt-4 mb-0">
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i>Only Basic Components
                    </li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i><span class="fw-bold text-dark">12+</span> Adv. Components
                    </li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i><span class="fw-bold text-dark">5</span> - Landing page</li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i><span class="fw-bold text-dark">3</span> Dashboard Layouts</li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i>Documentation</li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i>Access to support forums</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 col-12 mb-3">
              <!-- card -->
              <div class="card">
                <!-- card body -->
                <div class="card-body p-6 border-bottom mb-4">
                  <div class="d-flex align-items-center mb-3">
                    <div>
                      <!-- title -->
                      <h2 class="mb-0">Multisite
                      </h2>
                    </div>
                    <!-- text -->
                    <div class="ms-2">
                      <span class="badge bg-warning">Most Popular</span>
                    </div>
                  </div>
                  <!-- text -->
                  <p class="mb-0">For agile startups that want to grow their revenue with quick experiments and data-driven decision making.
                  </p>
                  <div class="d-flex align-items-end mt-6 mb-3">
                    <h1 class="fw-bold me-1 mb-0">$149 </h1>
                    <p class="mb-0">/mo</p>
                  </div>
                  <a href="#" class="btn btn-primary">Buy Multisite</a>
                </div>
                <!-- card body -->
                <div class="card-body">
                  <p class="mb-0">Everything in Standard +:</p>
                  <!-- list -->
                  <ul class="list-unstyled mt-4 mb-0">
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i>Offline viewing
                    </li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i><span class="fw-bold text-dark">Unlimited</span> projects
                    </li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i><span class="fw-bold text-dark">Unlimited</span> Storage </li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i>Custom domain support</li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i>Bulk editing</li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i>12 / 5 support
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 col-12 mb-3">
              <!-- card -->
              <div class="card">
                <!-- card body -->
                <div class="card-body p-6 border-bottom mb-4">
                  <h2 class="mb-3">Extended</h2>
                  <p class="mb-0">For fast-growth scaleups that want to grow by maximizing efficiencies in their revenue operations.
                  </p>
                  <!-- price -->
                  <div class="d-flex align-items-end mt-6 mb-3">
                    <h1 class="fw-bold me-1 mb-0">$490 </h1>
                    <p class="mb-0">/mo</p>
                  </div>
                  <a href="#" class="btn btn-outline-primary">Buy Extended</a>
                </div>
                <!-- card body -->
                <div class="card-body">
                  <p class="mb-0">Everything in Multisite + </p>
                  <!-- list -->
                  <ul class="list-unstyled mt-4 mb-0">
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i>Workshop</li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i><span class="fw-bold text-dark">Dedicated</span>hardware</li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i><span class="fw-bold text-dark">99.9% uptime</span>guarantee</li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i>Advanced analytics
                    </li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i>3rd party integrations
                    </li>
                    <li class="mb-1"><i data-feather="check-circle" class="me-2 text-success icon-xs"></i>24 / 7 supports
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- row -->
          <div class="row mb-10">
            <!-- col -->
            <div class="col-lg-12 col-md-12 col-12">
              <!-- text -->
              <div class="mb-6">
                <h2>Everything you need to build efficiently
                </h2>
                <p>Start building your app using our tools, be efficient, spend less time with details more time with your business.
                </p>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-4 col-md-6 col-12">
              <!-- text -->
              <div class="mb-6">
                <div class="icon-shape icon-lg bg-primary rounded-3 mb-4">
                  <i class="text-white" data-feather="edit"></i>
                </div>
                <h4>Create and Edit Projects</h4>
                <p>Donec posuere felis sit amet felis max imus roin consectetur quis leo id eleifuet, sapien quis fringilla finibus.
                </p>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-4 col-md-6 col-12">
              <!-- text -->
              <div class="mb-6">
                <div class="icon-shape icon-lg bg-primary rounded-3 mb-4">
                  <i class="text-white" data-feather="filter"></i>
                </div>
                <h4>Search and Filter</h4>
                <p>Vestibulum in neque augue. Vivamus sed tempor mi. Integer dolor urna, dictum a arcu vitae, efficitur semper lorem.
                </p>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-4 col-md-6 col-12">
              <!-- text -->
              <div class="mb-6">
                <div class="icon-shape icon-lg bg-primary rounded-3 mb-4">
                  <i class="text-white" data-feather="rotate-ccw"></i>
                </div>
                <h4>Real Time Updates</h4>
                <p>Maecenas nec mauris dui. Sed ut mi a nibh rhoncus blandit. Cras accumsan, eros in malesuada convallis.
                </p>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-4 col-md-6 col-12">
              <!-- text -->
              <div class="mb-6">
                <div class="icon-shape icon-lg bg-primary rounded-3 mb-4">
                  <i class="text-white" data-feather="tag"></i>
                </div>
                <h4>Meta Information
                </h4>
                <p>Aenean justo lorem, semper non lectus quis, porta semper enim. Integer posuere lorem eu neque pellentesque.
                </p>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-4 col-md-6 col-12">
              <!-- text -->
              <div class="mb-6">
                <div class="icon-shape icon-lg bg-primary rounded-3 mb-4">
                  <i class="text-white" data-feather="file-text"></i>
                </div>
                <!-- heading -->
                <h4>Pre rendered Results
                </h4>
                <p>Donec cursus libero non nibh consectetur sodales tincidunt vitae turpis. Duis feugiat at lorem id iaculis.
                </p>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-4 col-md-6 col-12">
              <!-- text -->
              <div class="mb-6">
                <div class="icon-shape icon-lg bg-primary rounded-3 mb-4">
                  <i class="text-white" data-feather="trending-up"></i>
                </div>
                <h4>Simple Analytics
                </h4>
                <p>Nulla imperdiet sem quis ipsum condi mentum po ris sit amet libero et turpis vestibulum faucibus at nec lacus.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- col -->
            <div class="col-12">
              <div class="mb-6">
                <h2 class="mb-0">Frequently Asked Questions</h2>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-4 col-md-6 col-12">
              <!-- text -->
              <div class="mb-6">
                <h4 class="">Will i be charged now for?</h4>
                <p>Vestibulum pulvinar est at erat laoreet fringilla. Nullam imperdiet, augue non vestibulum triuam quat maxim felis.
                </p>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-4 col-md-6 col-12">
              <!-- text -->
              <div class="mb-6">
                <h4 class="">How does a subscription work?</h4>
                <p>Donec tempus imperdiet libero quis ultricies. Donec nunc nisi, imperdiet nec porta ultrices, accumsan a nibh.
                </p>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-4 col-md-6 col-12">
              <!-- text -->
              <div class="mb-6">
                <h4 class="">Can I cancel anytime?</h4>
                <p>Yes, Pellentesque habitant morbi tristique senectus et netus fficitur eget lacus eu, gravida blandit sem. Duis aliquam convallis tempor
                </p>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-4 col-md-6 col-12">
              <!-- text -->
              <div class="mb-6">
                <h4 class="">How long is my personal?</h4>
                <p>Aliquam vel sodales est. Mauris eu dignissim dolor. Praesent scelerisque dolor risus, quis viverra interdum turpis tincidunt interdum.
                </p>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-4 col-md-6 col-12">
              <!-- text -->
              <div class="mb-6">
                <h4 class="">What are Multisite plan?</h4>
                <p>Quisque accumsan odio sed congue u eleifend est porttitor nisi lobortis, sit aget dolor rhoncus tincidunt vel a mauris.
                </p>
              </div>
            </div>
            <!-- col -->
            <div class="col-lg-4 col-md-6 col-12">
              <!-- text -->
              <div class="mb-6">
                <h4 class="">Are the files downloadable?</h4>
                <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fitur eget lacus eu, gravida blandit sem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>