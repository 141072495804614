import { EnvironmentInjector, Injectable } from '@angular/core';
import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { getBlob, getStorage, getDownloadURL, ref, uploadBytes, uploadString } from "firebase/storage";
import { environment } from './../environments/environment';

@Injectable({providedIn: "root"})
export class FirebaseService {
    
    app: FirebaseApp;
    analytics: Analytics;

    constructor() {
        this.app = initializeApp(environment.firebaseConfig);
        this.analytics = getAnalytics(this.app);
    }

    async saveFile(filename: string, file: File) {
        const storage = getStorage(this.app);
        const storageRef = ref(storage, "chat-bot/" + filename);

        await uploadBytes(storageRef, file);
        console.log(`${filename} uploaded to Firebase Storage.`);
    }

    async getBlob(filename: string): Promise<Blob> {
        const storage = getStorage(this.app);
        const storageRef = ref(storage, "chat-bot/" + filename);

        return getBlob(storageRef);
    }

    getDownloadURL(filename: string): Promise<string> {
        const storage = getStorage(this.app);
        const storageRef = ref(storage, "chat-bot/" + filename);

        return getDownloadURL(storageRef);
    }
}

