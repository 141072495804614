<div class="container d-flex flex-column">
  <div class="row align-items-center justify-content-center g-0
      min-vh-100">
    <div class="col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0">
      <!-- Card -->
      <div class="card smooth-shadow-md">
        <!-- Card body -->
        <div class="card-body p-6">
          <div class="mb-4">
            <a href="../index.html"><img src="../assets/img/brand/logo2.png" class="mb-2 w-100" alt=""></a>
            <p class="mb-6">Forgot the password? Don't worry, we'll send you an email to reset your password.</p>
          </div>
          <!-- Form -->
          <form>
            <!-- Email -->
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input #passwordResetEmail type="email" id="email" class="form-control" name="email"
                placeholder="Enter Your Email" required="">
            </div>
            <!-- Button -->
            <div class="mb-3 d-grid">
              <button type="submit" class="btn btn-primary"
                (click)="authService.ForgotPassword(passwordResetEmail.value)">
                Reset Password
              </button>
            </div>
            <div>Don't have an account? <a href="#" routerLink="/sign-up">Create account</a></div>
            <div class="mt-1">Suddenly remembered it? <a href="#" routerLink="/sign-in">Sign in</a></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>