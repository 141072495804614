import { AfterViewInit, Component, ElementRef, HostListener, NgZone, ViewChild } from '@angular/core';
import { Message } from "../../message";
import { ChatCompletionRequestMessage, Configuration, OpenAIApi } from 'openai';
import { BrowserFileService } from "../../browser-file.service";
import { MessageSection } from "../../message-section";
import { Suggestion } from "../../suggestion";
import { FirebaseService } from "../../firebase.service";
import { ImageFinderService } from "../../image-finder.service";
import { Conversation } from "../../conversation";
import { VocabularyService } from "../../vocabulary.service";
import { Correction } from "../../correction";
import { AuthService } from "src/app/shared/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ChatQueryParams } from "./chat-query-params";
import { RecordButtonComponent } from "../record-button/record-button.component";
import { Subject, asyncScheduler, throttleTime } from "rxjs";
import { SelectionInfo } from "src/app/selection-info";
import { TextToSpeechResult } from "../../../../../server/src/text-to-speech-result";
declare var $: any;

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements AfterViewInit {
  title = 'ChatGapNat';
  output = '';
  query = ``;
  dataKey = "user_onboarding_data";
  data: string = "";

  _question = "";
  showHiddenMessages = false;
  pendingMessage = "";
  showDebugTools = false;
  requestAbortController: AbortController | undefined;
  speakRate: number = 1;
  selectedText: string | undefined = "";
  autoSubmitRecording: boolean = false;
  showFullDefault: boolean = true;
  speakStallMessages: boolean = false;
  sidebarOpen: boolean = false;
  sidebarRightOpen: boolean = false;
  scrollToBottomWhenSelectingConversation: boolean = true;
  currentTranslation = "";
  autoTranslateQuestion = true;
  currentUserId = "2";
  aiUserId = "1";

  @ViewChild("recordButton")
  private recordButton: RecordButtonComponent | undefined;

  get question(): string {
    return this._question;
  }

  set question(value: string) {
    this._question = value;
    if (!this.question) {
      this.currentTranslation = ""
    } else {
      this.updateTranslation();
    }
  }

  private async updateTranslation() {
    this.currentTranslation = await this.translateText(this.question);
  }

  private readonly AssistantAvatar = "nat-avatar";
  private readonly AssistantName = "Nat";

  private readonly UserAvatar = "richard-avatar-2";
  private readonly UserName = "Richard";

  private readonly apiUrl = '/api';

  isYou(message: Message): boolean {
    return message.userId == this.currentUserId;
  }

  saveMessages(): void {
    localStorage.setItem("conversations", JSON.stringify(this.conversations));
  }

  async loadMessages() {
    this.conversations = await this.getConversations();
    if (this.conversations?.length) {
      this.setActiveConversationById();
    }
    else {
      this.initNewUser();
    }
  }

  initNewUser() {
    this.conversations = [];
    this.createChat("Chat", "");
  }

  public conversations: Conversation[] = [];
  public activeConversation: Conversation | undefined;

  newConversation() {
    $('#new-chat-modal').modal('show');
  }

  async createChat(name: string, prompt: string = "") {
    let newConversation: Conversation = {
      name: name,
      description: "",
      prompt: prompt,
      date: new Date().toUTCString(),
      messages: []
    }

    newConversation = await this.saveConversation(newConversation);

    this.conversations.unshift(newConversation);
    this.setActiveConversation(newConversation);
    this.sidebarOpen = false;
  }

  async setActiveConversationById(conversationId?: string) {
    if (!this.conversations.length)
      return;

    if (!conversationId) {
      conversationId = (<ChatQueryParams>this.activatedRoute.snapshot.queryParams).conversation || this.conversations[0].id;
    }

    if (this.activeConversation?.id == conversationId)
      return;

    const newActiveConversation = this.conversations.find(conversation => conversation.id == conversationId);
    if (newActiveConversation) {
      this.setActiveConversation(newActiveConversation);
      return;
    }
  }

  async setActiveConversation(conversation: Conversation) {
    if (!conversation)
      return;

    this.activeConversation = conversation;
    this.router.navigate([], {
      queryParams: <ChatQueryParams>{
        conversation: this.activeConversation?.id
      },
      queryParamsHandling: "merge"
    });
    const messages = await this.getMessages(this.activeConversation.id);
    this.messages = messages ?? [];
    if (!messages?.length) {
      this.initMessages();
    }

    this.messages.forEach(message => message.showFull = this.showFullDefault);

    if (this.scrollToBottomWhenSelectingConversation) {
      setTimeout(() => this.scrollToBottom(), 200);
    } else {
      this.scrollToTop(true);
      setTimeout(() => {
        this.isScrolledToBottom();
      }, 200);
    }
  }

  public clearMessages() {
    this.messages = [];
    this.initMessages();
  }

  public initMessages() {
    this.addMessage({
      user: {
        name: this.AssistantName,
        avatar: this.AssistantAvatar,
      },
      time: new Date().toLocaleTimeString(),
      text: "Hello I'm Nat. Lets speak Thai together!" + "\n" + this.activeConversation?.prompt,
      userId: this.aiUserId
    });
  }

  public deleteMessage(message: Message) {
    this.removeItem(this.messages, message);
    this.removeMessage(message);
    this.saveMessages();
  }

  loadData() {
    this.data = localStorage.getItem(this.dataKey) ?? "";
  }

  saveData(data: string) {
    localStorage.setItem(this.dataKey, data);
  }

  public async callOpenAI() {
    try {
      let answer = await this.rawCallToOpenAI();
      if (this.tempMessage) {
        this.addMessage(this.tempMessage, true);
        this.tempMessage = undefined;
      }

    } catch (error) {
      console.error(error);
      this.requestAbortController = undefined;
      this.addMessage({
        user: {
          name: this.AssistantName,
          avatar: this.AssistantAvatar,
        },
        userId: this.aiUserId,
        text: <string>"Sorry, an error occured! " + error,
        time: new Date().toLocaleTimeString(),
        error: true
      });
    } finally {
      this.pendingMessage = "";
    }
  }

  public async addMessage(message: Message, makePermanent?: boolean): Promise<Message> {
    if (this.messages[this.messages.length - 1]?.temporary) {
      //Replace temorary message
      this.messages.pop();
    }
    this.parseSections(message);
    this.messages.push(message);

    if (makePermanent) {
      message.temporary = false;
    }

    if (!message.temporary) {
      console.log("Messages:", this.messages);
    }

    try {
      if (!this.isYou(message) && message.canSpeak && !message.isSpoken) {
        message.isSpoken = true;
        message.audioFile = await this.speakText(<string>message.speakText, undefined, this.speakRate);
      }
    } catch (error) {
      console.error(error);
    }

    setTimeout(() => this.scrollToBottom(this.isYou(message)), 200);

    if (!message.temporary) {
      message = await this.saveMessage(message);
    }

    return message;
  }

  private async rawCallToOpenAI(): Promise<string> {
    let stallMessageSpoken = false;
    this.pendingMessage = "Thinking...";

    const messages: ChatCompletionRequestMessage[] = this.messages
      .filter(message => !message.type) //only messages without type goes back in the conversation
      .map(message => {
        return <ChatCompletionRequestMessage>{
          role: this.isYou(message) ? "user" : "assistant",
          content: message.text
        };
      });

    messages.unshift({
      role: "system",
      content: this.chatGapNatPrompt
    }
    );

    this.requestAbortController = new AbortController();
    const signal = this.requestAbortController.signal;

    const response = await this.fetchCompletions(messages, signal);

    // Read the response as a stream of data
    const reader = response?.body?.getReader();

    if (!reader)
      throw "No reader";

    const decoder = new TextDecoder("utf-8");
    let resultText = "";
    let retries = 0;
    let chunk = "";
    let oldChunks = "";
    let parsedLines = [];
    while (true) {
      try {
        const { done, value } = await reader.read();
        if (done) {
          this.requestAbortController = undefined;
          break;
        }
        // Massage and parse the chunk of data
        chunk = oldChunks + decoder.decode(value);
        const lines = chunk.split("data:");
        parsedLines = lines
          .map((line) => line.replace(/^data: /, "").trim()) // Remove the "data: " prefix
          .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
          .map((line) => {
            return JSON.parse(line);
          });

        if (!parsedLines?.length) {
          this.requestAbortController = undefined;
          break;
        }

      } catch (error) {
        oldChunks += chunk;
        console.error(error);
        retries++;
        if (retries < 3)
          continue;
      }

      if (!stallMessageSpoken && this.speakStallMessages) {
        stallMessageSpoken = true;
        setTimeout(() => {
          this.speakStallMessage();
        }, 2000);
      }

      for (const parsedLine of parsedLines) {
        const { choices } = parsedLine;
        const { delta } = choices[0];
        const { content } = delta;
        // Update the UI with the new content
        if (content) {
          resultText += content;
          this.pendingMessage = "";
          this.updateTempAnswer(resultText);
        }
      }

      chunk = "";
      oldChunks = "";
      parsedLines = [];
    }

    return resultText;
  }

  private async fetchCompletions(messages: ChatCompletionRequestMessage[], signal: AbortSignal): Promise<Response> {
    return await this.apiFetch('/completions', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        messages: messages
      }),
      signal,
    });
  }

  async correctText(text: string | undefined) {
    return (await this.getCorrection(text))?.corrected.text;
  }

  async getCorrection(text: string | undefined): Promise<Correction | null> {
    if (!text)
      return null;

    let correctionCompletion = await this.getCorrectedTextCompletion(text);
    console.log("CorrectionCompletion:", correctionCompletion);
    let json = this.extractJSON(correctionCompletion); //Strip extra text from the json object
    if (!json) {
      console.error("No json return from getCorrectedTextCompletion()", correctionCompletion);
      return null;
    }
    console.log("JSON:", json);
    const correction: Correction = JSON.parse(json);
    console.log("Correction:", correction);
    return correction;
  }

  private extractJSON(text: string) {
    const matches = text.match(/\{[\s\S]*\}/);

    if (matches && matches[0]) {
      try {
        return matches[0].toString();
      } catch (error) {
        console.error("Failed to find JSON:", error);
        return null;
      }
    }

    return null;
  }


  private async getCorrectedTextCompletion(text: string): Promise<string> {
    const messages: ChatCompletionRequestMessage[] = [
      {
        role: "system",
        content: "You are a very stringent and competent Thai speaking assistant that can correct Thai script that has been created with a speech-to-text api."
      },
      {
        role: "user",
        content: this.correctSpeechToTextInputPrompt.replace("%TEXT-TO-CORRECT%", text)
      }
    ];

    let requestAbortController: AbortController | undefined = new AbortController();
    const signal = requestAbortController.signal;

    const response = await this.fetchCompletions(messages, signal);

    // Read the response as a stream of data
    const reader = response?.body?.getReader();

    if (!reader)
      throw "No reader";

    const decoder = new TextDecoder("utf-8");
    let resultText = "";
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        requestAbortController = undefined;
        break;
      }
      // Massage and parse the chunk of data
      const chunk = decoder.decode(value);
      const lines = chunk.split("data:");
      const parsedLines = lines
        .map((line) => line.replace(/^data: /, "").trim()) // Remove the "data: " prefix
        .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
        .map((line) => {
          return JSON.parse(line);
        });

      if (!parsedLines?.length) {
        requestAbortController = undefined;
        break;
      }


      for (const parsedLine of parsedLines) {
        const { choices } = parsedLine;
        const { delta } = choices[0];
        const { content } = delta;
        // Update the UI with the new content
        if (content) {
          resultText += content;
          console.log(resultText);
        }
      }
    }

    return resultText;
  }

  public abortRequest() {
    this.requestAbortController?.abort();
    this.requestAbortController = undefined;
  }

  private tempMessage: Message | undefined;

  private updateTempAnswer(tempAnswer: string) {
    const result = this.extractUntilBracket(tempAnswer);

    if (!this.tempMessage) {
      this.tempMessage = {
        userId: this.aiUserId,
        user: {
          name: this.AssistantName,
          avatar: this.AssistantAvatar,
        },

        text: result.text,
        time: new Date().toLocaleTimeString(),
        canSpeak: result.found,
        showFull: this.showFullDefault,
        temporary: true
      };
    } else {
      this.tempMessage.speakText = result.text;
      this.tempMessage.text = tempAnswer;
      this.tempMessage.canSpeak = result.found;
      this.tempMessage.showFull = this.showFullDefault
    }

    this.addMessage(this.tempMessage);
  }

  extractUntilBracket(input: string) {
    const index = input.indexOf('[');
    if (index !== -1) {
      return { text: input.substring(0, index), found: true };
    }
    return { text: input, found: false };
  }

  constructor(
    private browserFileService: BrowserFileService,
    private firebaseService: FirebaseService,
    private imageFinderService: ImageFinderService,
    private vocabularyService: VocabularyService,
    private ngZone: NgZone,
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.loadData();
    if (this.data) {
      console.log("Data available");
    } else {
      console.log("No data available");
    }

    this.question = "";

    this.runTest();

    this.loadMessages();

    this.question = "";

    this.activatedRoute.queryParams.subscribe((queryParams: ChatQueryParams | any) => {
      const conversationId = queryParams.conversation;

      this.setActiveConversationById(conversationId);
    });

    this.selectedTextChanged$.pipe(throttleTime(1000, asyncScheduler, { leading: false, trailing: true })).subscribe(selectedText => {
      this.handleSelectedTextChanged(selectedText);
    });
  }

  public selectionInfo: SelectionInfo | undefined = undefined;

  async handleSelectedTextChanged(selectedText: string) {
    if (!selectedText) {
      this.selectionInfo = undefined;
    } else {
      this.selectionInfo = {
        text: selectedText,
        translation: await this.translateText(selectedText),
        elaboration: await this.elaborateOnText(selectedText),
      }
      this.sidebarRightOpen = true;
    }
  }

  async elaborateOnText(selectedText: string): Promise<string | undefined> {
    return "";
  }

  ngAfterViewInit(): void {
  }

  runTest() {
    console.log(this.parseMessageSections(this.testStringWithSections));
  }

  public async submit(event: Event) {
    event?.preventDefault();
    event?.stopImmediatePropagation();

    const question = this.question;
    this.submitQuestion(question);
  }

  private submitQuestion(question: string) {
    let newMessage: Message = {
      user: {
        name: this.UserName,
        avatar: this.UserAvatar,
      },
      time: new Date().toLocaleTimeString(),
      text: question,
      userId: this.currentUserId,
      translation: this.currentTranslation,
      audioFile: this.currentAudioFile
    };
    this.question = "";
    this.addMessage(newMessage);

    this.callOpenAI();
  }

  public async onValueChange(event: Event) {
    const value = (event.target as any).value;
    this.question = value;
  }

  public download(message: Message) {
    let extension = ".csv";
    let text = message.customData;
    if (typeof (text) !== "string") {
      text = JSON.stringify(text);
      extension = ".json";
    }

    this.browserFileService.downloadTextFile(text, "data" + extension);
  }

  public parseSections(message: Message) {
    message.sections = this.parseMessageSections(<string>message.text);
  }

  public parseMessageSections(text: string | undefined): MessageSection[] {
    if (!text) {
      return [];
    }

    const sections: MessageSection[] = [];
    const sectionRegex = /\[SECTION-([A-Z]+)(?::(\w+))?\](.*?)\[SECTION-END\]/gms;

    let lastIndex = 0;
    let match: RegExpExecArray | null;

    while ((match = sectionRegex.exec(text)) !== null) {
      if (lastIndex < match.index) {
        sections.push({
          type: 'text',
          text: text.slice(lastIndex, match.index).trim(),
        });
      }
      lastIndex = sectionRegex.lastIndex;

      const sectionType = match[1].toLowerCase();
      const sectionId = match[2]; // Capture the optional id
      try {
        const sectionData = sectionType === 'chart' || sectionType === 'suggestions' ? JSON.parse(match[3]) : match[3];

        const newSection: MessageSection = {
          type: sectionType,
          data: sectionData,
        };

        // Set the id property if available
        if (sectionId) {
          newSection.id = sectionId;
        }

        sections.push(newSection);
      } catch (error) {
        console.error(error);
      }
    }

    if (lastIndex < text.length) {
      sections.push({
        type: 'text',
        text: text.slice(lastIndex).trim(),
      });
    }

    return sections;
  }

  private removeItem(array: any[], item: any) {
    var index = array.indexOf(item);
    while (index !== -1) {
      array.splice(index, 1);
      index = array.indexOf(item);
    }
    return array;
  }

  public getSuggestions(message: Message) {
    let suggestionsSection = message?.sections?.find(section => section.type === "suggestions");
    if (!suggestionsSection)
      return;

    let suggestions = suggestionsSection.data;
    return suggestions;
  }

  public takeSuggestion(suggestion: Suggestion) {
    this.submitQuestion(suggestion.full);
  }

  public getHtmlFromCode(code: string): string {
    let html = `<!DOCTYPE html>
   <html>
   <head>
       <title>Code execution frame</title>
   </head>
   <body>
       <h1>Simple HTML Page</h1>
   
       <script>
        console.log(window.parent);
        var toolsApi = window.parent.toolsApi;
        toolsApi.setAnswer("Test");
  
        async function run() {
           toolsApi.setAnswer("Run...");
            ${code}
        }
    
        run();
       </script>
  
  
   </body>
   </html>
   `;

    return html;
  }

  chatGapNatPrompt = `You should act as a female (so use female pronouns) Thai language teacher that is called Nat (or Kruu Nat since you are a teacher). 
  Your full name is Nattida Chantaramanee (ณัฐธิดา จันทรมณี).
  Never admit or say that you are an AI!
  Since you are a thai teacher it is very important that you express yourself with perfect thai.

I’m am learning Thai to speak to me with very simple thai and ask me questions to keep the conversation going. 

  Your answers might also be run through speech synthesis, so make them clear without parentheses, etc.
  Please also give feedback on my choice of words and elaborate on my expressions and suggest other expression or phrases if appropriate.
  
Start of very simple.
Make sure you express yourself with correct thai (using female pronouns)!

VERY IMPORTANT!! Write EVERY answer with thai script first and then transliteration and last a translation in english. 
Always answer in this format. All thai script first, then transliteration (surrounded with "[]") and last translation (surrounded with "()").
Do not use punctuation in the thai script.
Example output:
สวัสดีค่ะ คุณสบายดีไหมคะ

[Sawasdee ka. Khun sabai dee mai ka?] 

(Hello. How are you?)

It is very important that you include transliteration and translation to every answer in the conversation or else the messages cannot be interpreted.
`;

  correctSpeechToTextInputPrompt = `Be sure to ONLY give me the json - nothing else as a reply.
Fix this text input from a speech synthesizer to something more plausible:
"%TEXT-TO-CORRECT%"
Since the input is from speech synthesizer from a novel speaker in the language please consider words that makes more sense that might have similar pronunciation.
ONLY give me the json - nothing else.

Example output:
{
        "original": {
            "text": "",
            "transliteration":"",
            "translation": ""
        },
        "corrected": {
            "text": "",
             "transliteration":"",
            "translation": ""
        }
}`;

  // You should finish your message with 1 to 3 suggestion of short answers that I can reply with to keep the conversation going. 
  // They will be presented in the UI as "quick-reply buttons" and should be provided in the format (an example): 
  // [SECTION-SUGGESTIONS][{"title" : "Tell me more", "full": "Please tell me more about that and I will answer."},
  // {"title" : "I like it", "full": "I like thai food as you asked about"}][SECTION-END]
  // `

  messages: Message[] = [];

  testStringWithSections = `User onboarding in the EWON Visualization platform is designed to ensure a smooth and enjoyable experience for new users. From the initial signup to navigating the interface, EWON focuses on providing clear guidance and intuitive design for easy adaptation to the platform.

  To make the experience even more delightful, let me share an image of a horse, as per your request:
  
  [SECTION-IMAGE]horse[SECTION-END]
  
  Furthermore, I would like to present to you a chart illustrating the distribution of the horse population:
  
  [SECTION-CHART]{ "title": { "text": "Horse Population Distribution" }, "xAxis": { "categories": ["Region A", "Region B", "Region C", "Region D"] }, "yAxis": { "title": { "text": "Population" } }, "series": [{ "name": "Horse Population", "data": [45, 80, 68, 95] }] }[SECTION-END]
  
  Please take a moment to explore the chart and get insights into the horse population distribution. If you have any questions or need further clarification on any aspect of the user onboarding process in EWON Visualization or anything else, feel free to ask!`;

  public async test() {
    this.callOpenAI();
  }

  public downloadFile(blob: Blob, fileName = "text.txt", mimeType = "text/plain", encoding = "ISO-8859-1") {
    const options: any = { type: mimeType, encoding: encoding };

    const aElement = document.createElement("a");
    aElement.download = fileName;
    aElement.href = URL.createObjectURL(blob);
    aElement.dataset["downloadurl"] = [mimeType, aElement.download, aElement.href].join(":");
    aElement.style.display = "none";
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);
    setTimeout(function () { URL.revokeObjectURL(aElement.href); }, 1500);
  }

  public downloadMessages() {
    let messagesJson = JSON.stringify(this.conversations);
    this.downloadFile(new Blob([messagesJson]), "messages.json");
  }

  public isRecording = false;
  public waitingForTranscription = false;
  private currentAudioFile: string = "";

  async recordingReady(recordedBlob: Blob) {
    try {
      this.waitingForTranscription = true;

      //Send audio to server and get transcription back
      const transcriptionResult: any = await this.transcribeAudio(recordedBlob);
      this.currentAudioFile = transcriptionResult.audioFile;

      if (this.autoSubmitRecording) {
        this.submitQuestion(transcriptionResult.transcription);
      } else {
        this.question += transcriptionResult.transcription;
      }
    } finally {
      this.waitingForTranscription = false;
    }
  }

  private cancelRecording = false;

  cancelRecord() {
    this.recordButton?.cancelRecord();
  }

  public async apiFetch(endpoint: string, init: RequestInit): Promise<Response> {
    const token = localStorage.getItem('token');

    init.headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    };

    const response = await fetch(this.apiUrl + endpoint, init);

    if (response.status === 401) {
      console.warn("401 received from API call, redirecting to login");
      this.router.navigate(["/sign-in"]);
    }

    return response;
  }

  public async transcribeAudio(blob: Blob): Promise<string> {
    try {
      const response = await this.apiFetch('/transcribe', {
        method: 'POST',
        body: blob
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const transcription = result.transcription;
      console.log(`Transcription:'${transcription}'`);

      return result;
    } catch (err) {
      console.error(err);
      throw err; // You might want to throw the error again so the calling function can handle it
    }
  }

  @ViewChild('scrollContainer')
  private scrollContainer: ElementRef | undefined;

  public scrollToBottom(instant?: boolean): void {
    this.ngZone.run(() => {
      this.scrollContainer?.nativeElement.scroll({
        top: this.scrollContainer?.nativeElement.scrollHeight,
        left: 0,
        behavior: instant ? "auto" : "smooth"
      });
    });
  }

  isScrolledToBottom(): boolean {
    const scrollArea = this.scrollContainer?.nativeElement.scrollHeight - this.scrollContainer?.nativeElement.offsetHeight;
    const fromBottom = scrollArea - this.scrollContainer?.nativeElement.scrollTop;
    return fromBottom < 5;
  }

  public scrollToTop(instant?: boolean): void {
    this.scrollContainer?.nativeElement.scroll({
      top: 0,
      left: 0,
      behavior: instant ? "auto" : "smooth"
    });
  }

  lastTextToSpeechResult?: TextToSpeechResult;

  public async speakText(text: string | undefined, languageCode: string = "th-th", speed: number = 1): Promise<string> {
    if (!text)
      return "";
    const response = await this.apiFetch("/speak", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: text,
        languageCode: languageCode,
        speed: speed
      })
    });

    if (!response.ok) {
      throw new Error('Network response wtransas not ok');
    }

    const result = await response.json();
    this.lastTextToSpeechResult = result;

    const audioFilename = result.audioFileName;
    console.log("TextToSpeechResult:", result)

    this.playAudioFile(audioFilename);

    return audioFilename;
  }

  public async translateText(text: string | undefined, sourceLanguage: string = "th-th", targetLanguage: string = "en"): Promise<string> {
    if (!text)
      return "";
    const response = await this.apiFetch("/translate", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: text,
        sourceLanguage: sourceLanguage,
        targetLanguage: targetLanguage
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    const translation = data.translation;

    return translation;
  }

  public currentAudio: HTMLAudioElement | undefined;

  public playAudioFile(audioFileName: string | undefined) {
    if (this.currentAudio) {
      this.abortAudio();
    }

    this.currentAudio = new Audio(this.apiUrl + "/audio/" + audioFileName);

    this.currentAudio.addEventListener('ended', () => {
      this.currentAudio = undefined;
    });
    this.currentAudio.play();
  }

  public abortAudio() {
    this.currentAudio?.pause();
    this.currentAudio = undefined;
  }

  hasAncestorWithClass(element: Node | null, className: string): boolean {
    while (element) {
      if ((<HTMLElement>element).classList && (<HTMLElement>element).classList.contains(className)) {
        return true;
      }
      element = element.parentNode;
    }
    return false;
  }

  getSelectedText() {
    var text: string | undefined = "";

    // Function to check if an element or any of its parents have a certain class

    var activeEl: any = document.activeElement;
    var activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
    if (
      (activeElTagName == "textarea") ||
      (activeElTagName == "input" && /^(?:text|search|password|tel|url)$/i.test(activeEl.type)) &&
      (typeof (<any>activeEl).selectionStart == "number")
    ) {
      text = activeEl.value.slice(activeEl.selectionStart, activeEl.selectionEnd);
    } else if (window.getSelection) {
      var selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        var commonAncestor = selection.getRangeAt(0).commonAncestorContainer;
        if (this.hasAncestorWithClass(commonAncestor, 'selectable-text')) {
          text = selection.toString();
        }
      }
    }

    return text;
  }

  playSelectedText() {
    this.speakText(this.selectedText);
  }

  private selectedTextChanged$: Subject<string> = new Subject<string>();

  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:keyup', ['$event'])
  @HostListener('document:selectionchange', ['$event'])
  updateSelection(event: Event) {
    const oldSelection = this.selectedText;
    this.selectedText = this.getSelectedText();
    if (this.selectedText != oldSelection && this.selectedText !== "") {
      this.selectedTextChanged$.next(this.selectedText ?? "");
    }
  }

  showModal() {
    $('#settings-modal').modal('show');
  }

  private readonly stallMessages: string[] = [
    "Thinking. Please wait.",
    "I am typing. Wait a little.",
    "Need some time. Hold on.",
    "Trying to find words. Wait.",
    "Thinking hard. Please stay.",
    "Wait. I write answer now.",
    "Give me moment. Still typing.",
    "I want give best answer. Wait please.",
    "Hold on. I think and write.",
    "Need some seconds. Typing now."
  ];

  public speakStallMessage() {
    const randomIndex = Math.floor(Math.random() * this.stallMessages.length);
    const text = this.stallMessages[randomIndex];
    this.speakText(text);
  }

  resubmit(message: Message) {
    const index = this.messages.lastIndexOf(message);
    if (index !== -1) {
      this.messages = this.messages.slice(0, index);
    }

    this.addMessage(message);
    this.callOpenAI();
  }

  deleteConversation(conversation: Conversation) {
    let index = this.conversations.indexOf(conversation);
    this.removeItem(this.conversations, conversation);
    if (conversation === this.activeConversation) {
      this.setActiveConversation(this.conversations[index] || this.conversations[index - 1]);
    }

    this.removeConversation(conversation);

  }

  editConversation(conversation: Conversation) {
    $('#conversation-settings-modal').modal('show');
    this.activeConversation = conversation;
  }

  async saveConversation(conversation: Conversation | undefined): Promise<Conversation> {
    const response = await this.apiFetch("/conversations", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(conversation)
    });

    const data = await response.json();
    return data;
  }

  async getConversation(id: string): Promise<Conversation> {
    const response = await this.apiFetch("/conversations/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    const data = await response.json();
    return data;
  }

  async getConversations(): Promise<Conversation[]> {
    const response = await this.apiFetch("/conversations", {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    const data = await response.json();
    return data;
  }

  async saveMessage(message: Message | undefined): Promise<Message> {
    const response = await this.apiFetch(`/conversations/${this.activeConversation?.id}/messages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(message)
    });

    const data = await response.json();
    return data;
  }

  async getMessages(conversationId: string | undefined): Promise<Message[]> {
    const response = await this.apiFetch(`/conversations/${conversationId}/messages`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    const messages = await response.json();
    return messages;
  }

  async removeMessage(message: Message | undefined): Promise<Message> {
    const response = await this.apiFetch(`/messages/${message?.id}`, {
      method: "DELETE"
    });

    const data = await response.json();
    return data;
  }

  async removeConversation(conversation: Conversation | undefined): Promise<Conversation> {
    const response = await this.apiFetch(`/conversations/${conversation?.id}`, {
      method: "DELETE"
    });

    const data = await response.json();
    return data;
  }
}

